<div>
  <div
    class="notification-icon-container gn-notification-icon-container u-floatRight"
    style="float: right; width: '35px'; height: '25px'; cursor: pointer"
    (click)="showNotification()"
    (keydown.enter)="showNotification()"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
  >
    <span style="cursor: pointer" id="" tabindex="0" title="Notification">
      <svg
        *ngIf="totalNotificationCount != 0"
        width="35px"
        height="25px"
        viewBox="0 0 30 26"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="overflow: visible"
      >
        <g id="bell-white-with-number-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path
            d="M12.482023,23.4487892 C12.482023,24.8207204 11.3705711,25.9321723 9.99863988,25.9321723 C8.62670868,25.9321723 7.51525677,24.8207204 7.51525677,23.4487892 Z M9.99863988,6.06510744 C10.404367,6.06510744 10.8009941,6.10401017 11.1849953,6.17828958 C11.1017225,6.58100362 11.0459389,6.99265281 11.0188926,7.41212748 C10.6898464,7.34315438 10.3484027,7.306799 9.99863988,7.306799 C7.2602961,7.306799 5.03187366,9.53522144 5.03187366,12.2735652 L5.03187366,19.1354287 L4.48111447,19.5040731 L2.29794479,20.9654061 L17.699335,20.9654061 L15.5161653,19.5040731 L14.9654061,19.1354287 L14.9659929,15.4615753 C15.3585317,15.7269239 15.7731309,15.9621121 16.2064648,16.1638142 L16.2070977,18.4720895 L19.9321723,20.9654061 L19.9321723,22.2070977 L0.0651074414,22.2070977 L0.0651074414,20.9654061 L3.79018211,18.4720895 L3.79018211,12.2735652 C3.79018211,8.84428907 6.56936374,6.06510744 9.99863988,6.06510744 Z"
            id="Combined-Shape"
            fill="#000000"
            fill-rule="nonzero"
          ></path>
          <rect width="22" height="14" style="fill: #d0021b" rx="6" x="8" />
          <text fill="#FFF" font-size="12" transform="translate(7)" tabindex="0" title="Total number of notifications">
            <tspan x="9" y="11" *ngIf="totalNotificationCount <= 9">{{ totalNotificationCount }}</tspan>
            <tspan x="6" y="11" *ngIf="totalNotificationCount > 9">9+</tspan>
          </text>
        </g>
      </svg>
    </span>
  </div>
  <div
    [@internal-messaging-pop-over]
    *ngIf="show"
    class="notification"
    (mouseenter)="onMouseEnterInNotification()"
    (mouseleave)="onMouseLeaveInNotification()"
  >
    <div class="notification-container">
      <div class="Col--12 notification-header">
        <div class="Col--10 heading">Notifications ({{ totalNotificationCount }})</div>
        <div class="Col--2">
          <span
            class="notification-close ml-4X"
            (click)="closeNotification()"
            (keydown.enter)="closeNotification()"
            tabindex="0"
            clickName="Notification Close"
            >&times;</span
          >
        </div>
      </div>

      <div class="Col--12 notification-body">
        <div *ngFor="let item of notificationsArray" (click)="openNotificationCenter(item?.title)">
          <div class="notification-list" *ngIf="item?.showActionNeeded">
            <div class="notification-title">{{ item?.title }} ({{ item?.count }})</div>
            <div class="notification-desc">{{ item?.new }}</div>
            <div class="notification-desc">{{ item?.expiry }}</div>
          </div>
        </div>
      </div>

      <div class="Col--12 notification-footer">
        <button (click)="openNotificationCenter(labels?.allTab)" class="Button Button--primary font-14">
          View all
        </button>
      </div>
    </div>
  </div>
</div>
