<header class="vz-global-header">
  <div class="mini-cart-placeholder">
    <div class="mr-6X">
      <app-internal-messaging></app-internal-messaging>
    </div>
    <div>
    </div>
  </div>
  <div [ngStyle]="isMVB && { display: 'none' }" id="gNavHeader" ng-non-bindable></div>

</header>
