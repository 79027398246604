/* eslint-disable @typescript-eslint/ban-types */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
// import ticketDetails from 'src/assets/mocks/ticketdetails.json';
// import ticketsList from 'src/assets/mocks/ticketLists.json';
import { WINDOW } from '../shared/utils/tokens';
import { map } from 'rxjs/operators';

@Injectable()
export class WBTSService {
  constructor(private http: HttpClient, @Inject(WINDOW) private window) {}

  getGlobalMbtVariables(): Object {
    return window['mbtGlobal'];
  }

  public getTicketDetails(payload) {
    return this.http.post<any>(
      '/ticketing/amsecure/landing/getTicketDetails',
      payload,
      { withCredentials: true }
    );
  }

//   getmockTicketDetails(): Observable<any> {
//     return of(ticketDetails).pipe(
//       map((response: any) => {
//         return response;
//       })
//     );
//   }

//   getmockTicketsList(): Observable<any> {
//     return of(ticketsList).pipe(
//       map((response: any) => {
//         return response;
//       })
//     );
//   }

  public retrieveProblemSubType(payload) {
    const mbtGlobal = <any>this.getGlobalMbtVariables();
    const headers = new HttpHeaders({
      AM_UID: mbtGlobal.userId,
      AM_ECPD_ID: mbtGlobal.ecpdId,
    });
    return this.http.post<any>(
      '/ticketing/amsecure/landing/getTicketingList',
      payload,
      { headers: headers, withCredentials: true }
    );
  }

  public getCategorys(): Observable<any> {
    const mbtGlobal = <any>this.getGlobalMbtVariables();
    const head = {
      AM_UID: mbtGlobal['userId'],
      AM_ECPD_ID: mbtGlobal['ecpdId']
    };
    const headers = new HttpHeaders(head);
    return this.http.post<any>('/ticketing/amsecure/createTicket/getTicketCategories',{ headers: headers });
  }

  public requestUpdate(payload): Observable<any> {
    return this.http.post<any>(
        '/ticketing/amsecure/details/requestUpdate',
        payload,
        { withCredentials: true }
      );
  }

  public closeTicket(payload): Observable<any> {
    return this.http.post<any>(
        '/ticketing/amsecure/details/changeStatus',
        payload,
        { withCredentials: true }
      );
  }

  public uploadAttachment(formData ) {
    return this.http.post<any>(
        '/ticketing/amsecure/attachment/uploadAttachment',
        formData,
        { withCredentials: true }
      );
  }
}
