import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { InternalMessagingService } from '../../services/internal-messaging.service';
import { Constants } from '../../constants/Constants';
import { UnreadNotificationResponse } from './internal-messaging-interface';
import { Router } from '@angular/router';
import { getGlobalServerInfo } from '../../../core/constants/aem.global';
import { WINDOW } from '../../utils/tokens';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-internal-messaging',
  templateUrl: './internal-messaging.component.html',
  styleUrls: ['./internal-messaging.component.scss'],
  animations: [
    trigger('internal-messaging-pop-over', [
      transition(':enter', [style({ opacity: 1 }), animate(100)]),
      transition(':leave', [animate('100ms ease-in', style({ opacity: 0 }))])
    ])
  ],
  providers: [InternalMessagingService]
})
export class InternalMessagingComponent implements OnInit {
  mouseLeaveEvnt: number;
  show: boolean;
  clickActive = false;
  hasDeviceInPlace = false;
  globalVariables;
  totalNotificationCount: number;
  loadResponse = false;
  public labels = Constants?.notification;
  private navItems = Constants?.navItems;
  public showIcon: boolean;
  showb360Verbiage;
  showActionNeeded = false;
  userRole: string;
  notificationArr: UnreadNotificationResponse;
  notificationsArray: any = [
    {
      title: this.labels?.actionRequiredtitle,
      count: 0,
      new: 0,
      expiry: 0,
      showActionNeeded: this.showActionNeeded
    },
    {
      title: this.labels?.generalTitle,
      count: 0,
      new: 0,
      showActionNeeded: true
    }
  ];
  constructor(
    @Inject(WINDOW) private window,
    private internalMessagingService: InternalMessagingService,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.globalVariables = this.window['mbtGlobal'] || {};
    this.initializeVariables();
    this.userRole = this.globalVariables?.userRole?.toUpperCase();
    this.showActionNeeded = this.shouldShowActionNeeded();
    this.fetchList();
  }

  initializeVariables(): void {
    this.show = false;
    this.totalNotificationCount = 0;
    this.loadResponse = false;
    this.showIcon = false;
    this.clickActive = false;
  }

  shouldShowActionNeeded(): boolean {
    const rolesToShowAction = ['ADMIN', 'PRIMARYCONTACT', 'ENDUSER', 'CUS'];
    return rolesToShowAction.some((role) => this.userRole?.includes(role));
  }

  fetchList(): void {
    const payload = {
      userRole: this.userRole,
      userEmail: this.globalVariables?.userEmail ?? '',
      ecpdId: this.globalVariables?.ecpdId ?? '',
      segment: this.globalVariables['smbMediumProfile']
        ? 'SMB'
        : this.globalVariables['isFedCustomer']
        ? 'PUBLIC'
        : 'ENTERPRISE'
    };
    this.internalMessagingService?.fetchUnreadMessagesCount(payload).subscribe(
      (data) => this.processSuccessResponse(data),
      () => this.processErrorResponse()
    );
  }

  private processSuccessResponse(data: any): void {
    this.notificationArr = data;
    this.totalNotificationCount = this.notificationArr?.totalMessageCount;
    this.updateNotificationsArray();
    this.cdRef.detectChanges();
  }

  private processErrorResponse(): void {
    this.updateNotificationItem(0, this.notificationArr?.unReadGeneralMessageCount);
    this.updateNotificationItem(1, this.notificationArr?.unReadApprovalMessageCount);
    this.totalNotificationCount = 0;
    this.cdRef.detectChanges();
  }

  private updateNotificationsArray(): void {
    this.updateNotificationItem(
      1,
      this.notificationArr?.unReadGeneralMessageCount,
      this.notificationArr?.unReadGeneralMessageCountArrivedToday
    );
    this.updateNotificationItem(
      0,
      this.notificationArr?.unReadApprovalMessageCount,
      this.notificationArr?.unReadApprovalMessageCountArrivedToday,
      this.notificationArr?.unReadApprovalMessageCountExpiryingToday
    );
  }

  private updateNotificationItem(index: number, count: number, newCount?: number, expiryCount?: number): void {
    this.notificationsArray[index].new = `${newCount} ${this.labels?.newNotifications}`;
    this.notificationsArray[index].count = count;
    if (index == 0) this.notificationsArray[index].expiry = `${expiryCount} ${this.labels?.expiringToday}`;
    this.notificationsArray[index].showActionNeeded = this.showActionNeeded;
  }

  public openNotificationCenter(title: any) {
    const appHost = getGlobalServerInfo()['appHost'];
    const titlePathMapping = {
      [this.labels?.actionRequiredtitle]: this.labels?.actionNeeded,
      [this.labels?.generalTitle]: this.labels?.general,
      [this.labels?.allTab]: this.labels?.allTab
    };

    const path = titlePathMapping[title];
    if (path) {
      window.location.href = `${appHost}${this.navItems?.notification?.path}/${path}`;
    } else {
      return;
    }
  }

  static getGlobalMbtVariables(): any {
    return window['mbtGlobal'];
  }

  showNotification() {
    this.clickActive = true;
    if (!this.show) {
      this.show = true;
    }
  }
  onMouseEnter() {
    if (!this.show) {
      this.show = true;
    }
  }
  /**
   * on mouse leave open the popup
   */
  onMouseLeave() {
    this.mouseLeaveEvnt = window.setTimeout(() => {
      if (!this.clickActive) {
        this.show = false;
        this.cdRef?.detectChanges();
      }
    }, 1000);
  }
  /**
   * on mouse enter opens the popup
   */
  onMouseEnterInNotification() {
    if (!this.clickActive && this.show) {
      clearTimeout(this.mouseLeaveEvnt);
    }
  }
  /**
   * on mouse leave open the popup
   */
  onMouseLeaveInNotification() {
    if (!this.clickActive && this.show) {
      this.show = !this.show;
    }
  }
  closeNotification() {
    this.show = false;
    this.clickActive = false;
  }
}
