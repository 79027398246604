import { Inject, Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { WINDOW } from '../utils/tokens';
import { environment } from 'src/environments/environment';
import { Helper } from '../utils/helper';

@Injectable()
export class LinksetGuard implements CanLoad {
  constructor(@Inject(WINDOW) private window, private router: Router) {}

  canLoad(): boolean {
    const mbtGlobal = this.window['mbtGlobal'];
    if (mbtGlobal.mergedUser && mbtGlobal.lpocId) {
      if (Helper.hasPilotFlag('MBT_LINKSET_USERS_FLAG')) {
        this.router.navigate(['ebilling/linkset-users']);
      } else {
        Helper.redirect(environment.url.linksetUsers);
      }
      return false;
    } else {
      return true;
    }
  }
}
