import { Component, OnInit } from '@angular/core';
import { CommonService } from './services/common/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private commonService: CommonService) {
    
  }

  ngOnInit(): void {
    this.commonService.getEntryInfo().subscribe(userData => {
      if(userData) {
        this.commonService.userDetails = userData;
        // this.commonService.getTicketList().subscribe(ticketList => {

        // });
      } else {
        // route to error page
      }
    });
  }

  
}
