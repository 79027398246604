<p-dialog
  [modal]="true"
  [(visible)]="visible"
  position="right"
  [showHeader]="false"
>
  <div class="container" *ngIf="enableDetails">
    <div class="Grid u-marginNone">
      <div class="u-flex u-flexRow Col Col--lg9 Col--xl9 u-paddingNone">
        <h1
          class="u-marginBottomNone u-paddingLeftLarge u-paddingRightSmall u-paddingTopMedium"
        >
          {{ ticketDetails?.ticketNumber }}
        </h1>
        <span class="fs-8X u-paddingTopMedium u-flexJustifyBetween font-wg">
          | {{ ticketDetails?.ticketStatus }}</span
        >
      </div>
      <div
        class="Col Col--lg3 Col--xl3 fs-6X u-paddingTopMedium font-wg pointer"
      >
        <i
          (click)="closeModal()"
          class="onedicon icon-close alignIcon close-button u-paddingRightLarge"
        ></i>
      </div>
    </div>
    <p class="align-left u-marginBottomNone u-paddingLeftLarge">
      Customer Reference number:
      <span class="boldText">{{ ticketDetails?.customerReferenceNumber }}</span>
    </p>
    <p class="align-left u-paddingLeftLarge">
      Problem Catogory:
      <span class="boldText">{{ ticketDetails?.problemType }}</span>
    </p>
    <div
      *ngIf="ticketDetails?.ticketStatus !== 'Closed'"
      class="u-paddingAllMedium"
    >
      <button
        (click)="requestUpdate(ticketDetails?.ticketNumber)"
        class="Button btnClass font-16 bold"
      >
        Request update
      </button>
      <button
        (click)="confirmVisible = true"
        class="Button btnClass font-16 bold"
      >
        Close ticket
      </button>
    </div>
    <div class="Grid u-marginNone">
      <div class="Col Col--lg6 Col--xl6 u-paddingLeftLarge">
        <div class="u-flex u-flexRow boldText borderTopBlack u-paddingTopSmall">
          <h4 class="fs-5X">Ticket history</h4>
          <span
            class="u-paddingTopExtraSmall alignInfoIconEsim tooltipTop iMenuToolTip"
            tabindex="0"
          >
            <i class="onedicon icon-info u-paddingLeftMedium i-font-wg"></i>
            <div
              role="tooltip"
              aria-label="tooltip"
              class="table-heading-tooltip Tooltip--top"
            >
              <div class="test u-textLineHeightLoose">
                <div class="tooltip-menu-item">
                  <p>{{ tooltipContent }}</p>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div class="historyHeight" *ngFor="let history of ticketDetails?.ticketHistory">
          <p class="boldText u-marginNone">
            {{ history?.createdBy }}
            <span class="u-paddingLeftLarge">{{ history?.date }}</span>
          </p>
          <p class="u-marginNone">{{ history?.note }}</p>
        </div>
      </div>
      <div
        *ngIf="ticketDetails?.ticketStatus !== 'Closed' || (ticketDetails?.ticketStatus === 'Closed' && reopenFlag)"
        class="Col Col--lg6 Col--xl6"
      >
        <h4 class="fs-5X borderTopBlack u-paddingTopSmall">
          Request type correspondence
        </h4>
        <div>
          <p class="fs-3X u-marginNone">Message</p>
          <input
            type="text"
            class="Form-input borderInput inputClass"
            [formControl]="messageField"
          />
          <p class="fs-3X u-marginNone close-button">0/200</p>
        </div>
        <div class="u-paddingTopExtraLarge">
          <p class="fs-3X">
            Attachments
            <span class="u-paddingLeftLarge optionalClass">Optional</span>
          </p>
          <div class="card flex justify-content-center">
            <p-toast /><p-fileUpload             
              chooseLabel="Drag and drop or browse to attach files"
              chooseIcon="none"
              [showUploadButton]="false"
              [showCancelButton]="false"
              (uploadHandler)="onUpload($event)"
              
              [customUpload]="true"
              chooseStyleClass="uploadButton"
              [multiple]="true"
              [auto]="true"
              mode="advanced"
              name="file" 
             
              accept=".csv,.xml,.tsv,image/*"
              [maxFileSize]="4000000"
            >
              <ng-template pTemplate="content">
                <ul *ngIf="uploadedFiles.length">
                  <li *ngFor="let file of uploadedFiles">
                    {{ file.name }}
                    <p-button icon="pi pi-times" rounded="true" (onClick)="onRemove($event)"  outlined="true"  severity="secondary" />                 
                  </li>
                </ul>
              </ng-template>
            </p-fileUpload>
            <!-- <input
              type="text"
              placeholder="Drag and drop or browse to attach files"
              class="inputDottedClass Form-input borderInputDotted"
              readonly
            /> -->
          </div>
        </div>
        <div>
          <p class="fs-3X u-marginNone optionalClass">
            Maximum of <span class="boldText">5 files</span>
          </p>
          <p class="fs-3X u-marginNone optionalClass">
            Maximum file size <span class="boldText">4 MB</span>
          </p>
          <p class="fs-3X u-marginNone optionalClass">
            Supported file types: BMP, GIF, JPG, JPEG, PNG; PDF, XLS, CSV, DOC,
            DOCx, XML
          </p>
        </div>
        <div class="u-paddingTopExtraLarge">
          <button (click)="uploadFiles()"
            *ngIf="!reopenFlag"
            class="Button btnClass font-16" 
          >
            Send
          </button>
          <button
            *ngIf="reopenFlag"
            class="Button btnClass font-16"
          >
            Reopen ticket
          </button>
        </div>
      </div>
    </div>
    <div class="borderTopBlack u-paddingAllLarge">
      <p-accordion collapseIcon="pi pi-angle-up" expandIcon="pi pi-angle-down">
        <p-accordionTab header="Ticket details">
          <p class="fs-5X u-paddingTopLarge">Problem type</p>
          <div class="Grid u-marginNone">
            <div class="Col Col--lg6 Col--xl6 u-paddingNone u-paddingTopSmall">
              <p class="fs-14 boldText u-marginNone">Type</p>
              <p class="fs-14 u-marginNone">{{ ticketDetails?.mappedCategoryType }}</p>
            </div>
            <div class="Col Col--lg6 Col--xl6 u-paddingNone u-paddingTopSmall">
              <p class="fs-14 boldText u-marginNone">Sub-type</p>
              <p class="fs-14 u-marginNone">
                {{ ticketDetails?.mappedSubCategoryType }}
              </p>
            </div>
          </div>
          <p class="fs-5X u-paddingTopLarge">Identified services</p>
          <div class="Col Col--lg6 Col--xl6 u-paddingNone u-paddingTopSmall">
            <p class="fs-14 boldText u-marginNone">Wireless number</p>
            <p class="fs-14 u-marginNone">{{ ticketDetails?.wirelessNum }}</p>
          </div>
          <p class="fs-5X u-paddingTopLarge">Problem Specifics</p>
          <div class="flexClass u-marginNone">
            <div class="item u-paddingNone u-paddingTopSmall u-paddingRightMedium" *ngFor="let item of ticketDetails?.ticketQuestions">
              <p class="fs-14 boldText u-marginNone"> {{ item?.question }}</p>
              <p class="fs-14 u-marginNone">
                {{ item?.answer }}
              </p>
            </div>
          </div>
          <!-- <div class="Grid u-marginNone">
            <div class="Col Col--lg6 Col--xl6 u-paddingNone u-paddingTopSmall">
              <p class="fs-14 boldText u-marginNone">
                Device or messaging app changed recently
              </p>
              <p class="fs-14 u-marginNone">No</p>
            </div>
            <div class="Col Col--lg6 Col--xl6 u-paddingNone u-paddingTopSmall">
              <p class="fs-14 boldText u-marginNone">
                Sending message to own MDN worked
              </p>
              <p class="fs-14 u-marginNone">Yes</p>
            </div>
          </div>
          <div class="Grid u-marginNone">
            <div class="Col Col--lg6 Col--xl6 u-paddingNone u-paddingTopSmall">
              <p class="fs-14 boldText u-marginNone">Signal strength</p>
              <p class="fs-14 u-marginNone">Good</p>
            </div>
            <div class="Col Col--lg6 Col--xl6 u-paddingNone u-paddingTopSmall">
              <p class="fs-14 boldText u-marginNone">Problem start date</p>
              <p class="fs-14 u-marginNone">01 January 2024</p>
            </div>
          </div> -->
          <p class="fs-5X u-paddingTopLarge">Additional details</p>
          <div class="Grid u-marginNone">
            <div class="Col Col--lg6 Col--xl6 u-paddingNone u-paddingTopSmall">
              <p class="fs-14 boldText u-marginNone">Address</p>
              <p class="fs-14 u-marginNone">Address line 1</p>
              <p class="fs-14 u-marginNone">Address line 2</p>
              <p class="fs-14 u-marginNone">City, State</p>
              <p class="fs-14 u-marginNone">Zip code</p>
            </div>
            <div class="Col Col--lg6 Col--xl6 u-paddingNone u-paddingTopSmall">
              <p class="fs-14 boldText u-marginNone">Primary contact</p>
              <p class="fs-14 u-marginNone">
                {{ ticketDetails?.primaryContact?.firstName }}
                {{ ticketDetails?.primaryContact?.lastName }}
              </p>
              <p class="fs-14 u-marginNone">
                {{ ticketDetails?.primaryContact?.emailAddress }}
              </p>
              <p class="fs-14 u-marginNone">
                {{ ticketDetails?.primaryContact?.contactNumber }}
              </p>
            </div>
          </div>
          <div class="Grid u-marginNone">
            <div class="Col Col--lg6 Col--xl6 u-paddingNone u-paddingTopSmall">
              <p class="fs-14 boldText u-marginNone">Secondary contact</p>
              <p class="fs-14 u-marginNone">
                {{ ticketDetails?.secondaryContact?.firstName }}
                {{ ticketDetails?.secondaryContact?.lastName }}
              </p>
              <p class="fs-14 u-marginNone">
                {{ ticketDetails?.secondaryContact?.emailAddress }}
              </p>
              <p class="fs-14 u-marginNone">
                {{ ticketDetails?.secondaryContact?.contactNumber }}
              </p>
            </div>
            <div class="Col Col--lg6 Col--xl6 u-paddingNone u-paddingTopSmall">
              <p class="fs-14 boldText u-marginNone">Attachments</p>
              <p class="fs-14 u-marginNone" *ngFor="let fileName of ticketDetails?.ticketAttachments">
                {{ fileName.contentName }}
              </p>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
    <p-dialog
      header="Close ticket"
      [modal]="true"
      [(visible)]="confirmVisible"
      [style]="{ width: '25rem' }"
    >
      <span class="p-text-secondary block mb-5"
        >We are glad that your issue is resolved. Please share the resolution so
        that we may update our records.</span
      >
      <input
        type="text"
        class="Form-input borderInput u-paddingAllMedium u-marginTopMedium confirmInputClass"
        [(ngModel)]="statusChangeReason"
      />
      <div class="u-paddingAllMedium u-marginTopMedium " style="float: right;"
    >
      <button
        (click)="confirmVisible = false"
        class="Button btnClass font-16 bold"
      >
      Cancel
      </button>
      <button
        (click)="closeTicket(ticketDetails?.ticketNumber)"
        class="Button btnClass font-16 bold"
      >
      Submit
      </button>
    </div>
    </p-dialog>
  </div>
</p-dialog>
