declare const SERVER_INFO;
import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { DynamicScriptLoaderService } from './dynamic-script-loader.service';
import { forkJoin } from 'rxjs';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import {
  aemApiPath,
  aem_commerce_base_template_path,
  aem_error_card,
  aem_homepage_base_template_path,
  getAemApiKeys,
  getGlobalServerInfo
} from '../../../constants/aem.global';

export interface Scripts {
  name: string;
  src: string;
  type: string;
  loaded: false;
}

export class AemContent {
  aemKey: string;
  body: string;
  scripts: Scripts[];
  response: any;
  parsedHtml: Document;
}

@Injectable({
  providedIn: 'root'
})
export class AemService implements OnDestroy {
  stop$ = new Subject();
  aemPublishUrl = '/content';

  STATE = 0;

  private aemContentList: AemContent[] = [];

  private aemStore = new BehaviorSubject(this.STATE);

  aemStore$: Observable<any> = this.aemStore.asObservable();

  errorAemCardResponse: any;

  private localAemScript: Scripts[] = [
    // { name: 'bootstrap.js', src: `/scripts/bootstrap.js`, type: 'js', loaded: false }
  ];

  constructor(private _http: HttpClient, private dynamicScriptLoader: DynamicScriptLoaderService) {
    this.init();
  }

  init() {
    getAemApiKeys().forEach((key) => {
      const content = new AemContent();
      content.aemKey = key;
      this.aemContentList.push(this.createAemApiRequest(content));
    });
  }

  callAemPublish(key) {
    const publishUrl = this.aemPublishUrl + key;
    const url = (SERVER_INFO && SERVER_INFO.staticHost) ? SERVER_INFO.staticHost + publishUrl : publishUrl;
    return this._http.get(url, { headers: {'NoLoadingDialog' : 'true'  }});
  }

  encodeAEMResponse(response: string): string {
    console.log('response' + response);
    const filteredResponse = response
      .replace(/mbqa\d+.sdc.vzwcorp.com/g, '{{MBT_AEM_URL}}')
      .replace(/mb.verizonwireless.com/g, '{{MBT_AEM_URL}}');
    return filteredResponse
      .replace(/b2bqa\d+.sdc.vzwcorp.com/g, '{{MB_AEM_URL}}')
      .replace(/b2b.verizonwireless.com/g, '{{MB_AEM_URL}}');
  }

  decodeAEMResponse(response: string): string {
    const appHost = getGlobalServerInfo()['appHost'];
    const mbBaseHost = getGlobalServerInfo()['mbBaseHost'];
    return response.replace(/https?:\/\/{{MBT_AEM_URL}}/g, appHost).replace(/https?:\/\/{{MB_AEM_URL}}/g, mbBaseHost);
  }

  lazyloadAemCard(
    storeAemCardforLaterUse: Subject<any>,
    _document: Document,
    renderer: Renderer2,
    aemClassMapContent: any[],
    index?: number
  ): any {
    return storeAemCardforLaterUse.pipe(takeUntil(this.stop$)).subscribe(
      (aemCardResponse) => {
        if (index && aemCardResponse.aemContent && aemCardResponse.index === index) {
          this.loadAemCard(aemCardResponse.aemContent, _document, renderer, aemClassMapContent, aemCardResponse.index);
        }
      },
      (error) => console.error(error),
      () => console.log('Aem replay complete for plan card')
    );
  }

  loadAemCard(
    aemContent: AemContent,
    _document: Document,
    renderer: Renderer2,
    aemClassMapContent: any[],
    index: number
  ) {
    console.log('Aem replay ' + aemContent);
    this.loadAemCards(
      aemContent.parsedHtml,
      renderer,
      _document,
      this.getAemCardDivInfo(index, aemClassMapContent).cardClassName,
      this.getAemCardDivInfo(index, aemClassMapContent).aemClassName
    );
  }

  getAemCardDivInfo(index: number, aemClassMapContent: any[]): any {
    const aemObj = aemClassMapContent.find((x) => x.arraIndex === index);
    // console.log(aemObj);
    return aemObj;
  }

  showAemCard(index: number, aemClassMapContent: any[]): any {
    return aemClassMapContent.find((x) => {
      return x.showAEMCard === true && x.arraIndex === index;
    });
  }

  private getBasePath(key: string): any {
    if (key === 'commerce-footer') {
      return getGlobalServerInfo()['staticHost'] + aem_commerce_base_template_path;
    } else {
      return getGlobalServerInfo()['mbBaseHost'] + '/esmaem' + aem_homepage_base_template_path;
    }
  }

  // private getAEMHost(key: string): any {
  //   //  console.log('GIRISH:::'+key);
  //   return getGlobalServerInfo()['mbBaseHost'] + '/esmaem' + key;
  // }

  private createAemApiRequest(aemContent: AemContent): AemContent {
    this.dynamicScriptLoader.intializeScriptStore();
    const publishUrl = aemApiPath(this.getBasePath(aemContent.aemKey), aemContent.aemKey);
    //   console.log('publish URL::'+ aemContent.aemKey + '::'+ publishUrl);
    aemContent.response = this._http.get(publishUrl).pipe(
      map((res) => res),
      catchError(() => {
        return of(this.errorAemCardResponse);
      })
    );
    return aemContent;
  }

  public getErrorAEMCard(): Observable<any> {
    return this._http.get(
      this.dynamicScriptLoader.getStaticHost(true, getGlobalServerInfo()['staticHost']) + aem_error_card
    );

    // return this._http.get('../../assets' + aem_error_card);
  }

  public setErrorAemCard(data: any) {
    this.errorAemCardResponse = data;
  }

  public requestDataFromAEM(): Observable<any[]> {
    console.log('dashboardtest');
    const requestArray = [];
    this.aemContentList.forEach((content: AemContent) => {
      console.log('dashboardtest' + content.response);
      requestArray.push(content.response);
    });
    return forkJoin(requestArray);
  }

  public populateAEMResponse(index: number, responseBody: any) {
    let body = responseBody && (<any>responseBody).body ? (<any>responseBody).body : '';
    //    console.log('body'+body);
    responseBody['body'] = body = this.decodeAEMResponse(body);
    this.aemContentList[index].body = responseBody;
    this.aemContentList[index].parsedHtml = this.dynamicScriptLoader.extractAemContentBody(body);
    this.dynamicScriptLoader.aemScriptsFiltering(this.aemContentList[index].parsedHtml, ['js', 'css']);
  }

  private getAemScriptList(): Scripts[] {
    this.localAemScript.forEach((script) => {
      // Load Aem script from local assets folder
      this.dynamicScriptLoader.addScripts(script.name, script.src, true, script.type);
    });
    return this.dynamicScriptLoader.getUpdatedScriptStore();
  }

  public loadAemScripts() {
    this.dynamicScriptLoader.intializeScriptStore(this.getAemScriptList());
    this.dynamicScriptLoader
      .loadAemScripts()
      .then((_data: Scripts[]) => {
        let loaded = true;
        _data
          .filter((item) => item.loaded === false)
          .forEach((item) => {
            loaded = false;
            console.log('##### AEM scripts/styles failed to load:' + item.name);
          });
        if (loaded) {
          console.log('###### AEM side everything has been loaded!');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  public aemScriptRender(parsedHtml: Document, _document: Document) {
    if (parsedHtml) {
      Array.from(parsedHtml.getElementsByTagName('script')).forEach((script) => {
        // console.log(script.textContent);
        this.dynamicScriptLoader.addPageSpecificScript(script.textContent, _document);
      });
    }
  }

  public loadAemSlides(
    parsedHtml: Document,
    renderer: Renderer2,
    _document: Document,
    elRef: ElementRef,
    targetDivClassName: string
  ) {
    const loadedDom = new DOMParser().parseFromString(parsedHtml.body.innerHTML, 'text/html');
    this.dynamicScriptLoader.aemPodSlideRendere(parsedHtml, renderer, targetDivClassName, elRef);
    this.aemScriptRender(loadedDom, _document);
  }

  public loadAemCards(
    parsedHtml: Document,
    renderer: Renderer2,
    _document: Document,
    targetDivClassName: string,
    aemTargetDivClass: string
  ) {
    const loadedDom = new DOMParser().parseFromString(parsedHtml.body.innerHTML, 'text/html');
    this.dynamicScriptLoader.aemPodCardRendere(parsedHtml, renderer, targetDivClassName, aemTargetDivClass);
    this.aemScriptRender(loadedDom, _document);
  }

  pushAemContent() {
    this.aemStore.next(<any>this.aemContentList);
  }

  ngOnDestroy() {
    this.stop$.next(null);
  }
}
