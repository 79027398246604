import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  showLoader: boolean;

  private subscriptions: Subscription = new Subscription();

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.subscriptions.add(
      this.loaderService.getLoader().subscribe((state: boolean) => {
        this.showLoader = state;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
