import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ExceptionHandlerService } from '../exceptionHandler/exception-handler.service';
import { RequestURL } from '../../constants/url-constants'

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  
  userDetails: any;
  _problemCategoryData: any;
  _draftData: any;
  defaultfilterOptions = {
    currentPage: "1",
    fromDate: {
      open: false,
      value: ''
    },
    lastUpdated: '90days',
    limit: 10,
    searchBy: 'ticketNum',
    searchValue: '',
    sortBy: 'ticketNumber',
    sortDirection: 'desc',
    status: 'all',
    toDate: {
      open: false,
      value: ''
    }
  }
  min = 1000000000000; // Minimum 13-digit number
  max = 9999999999999; // Maximum 13-digit number  
  problemTypes: any[] = [];
  _ticketConfirmationAlert: any;
  // _ticketFailureAlert: boolean;
  
  constructor(private http: HttpClient, 
    private exceptionHandler: ExceptionHandlerService,
    private datePipe: DatePipe) {}

  getEntryInfo() {
    const randomNum = Math.floor(Math.random() * (this.max - this.min + 1)) + this.min;
    const headers = new HttpHeaders({
      'Cache-Control': 'no-store'
    });

    // to add randomNum append this with request -> + "?_=" + randomNum 
    return this.http.get(RequestURL.api.entryInfo, { headers }).pipe(
      catchError(this.exceptionHandler.handleError)
    );
  }

  getTicketList(filterOptions?: any) {
    filterOptions = filterOptions ? filterOptions : this.defaultfilterOptions;
    const body = this.updateFilterOptions(filterOptions);
    return this.http.post<any>(RequestURL.api.getTicketList, body)
      .pipe(
        map(response => this.getDataArr(response)), 
        catchError(this.exceptionHandler.handleError)
      );
  }

  getProblemCategory() {
    return this.http.post<any>(RequestURL.api.getTicketCategories, {})
      .pipe(
        map(response => this.getDataArr(response)), 
        catchError(this.exceptionHandler.handleError)
      );
  }

  updateFilterOptions(filterOptions: any): any {
    filterOptions.searchValue = this.removeFormatCharacters(filterOptions.searchValue);
    filterOptions.fromDate = this.datePipe.transform(filterOptions.fromDate.value, 'MM/dd/yyyy');
    filterOptions.toDate = this.datePipe.transform(filterOptions.toDate.value, 'MM/dd/yyyy');
    return filterOptions;
  }

  removeFormatCharacters(deviceId) {
    return deviceId.replace(/[-().\s]/g, "");
  }

  getDataArr(responseData) {
    if(responseData?.status === "Success" && 
      responseData?.dataArray &&
      responseData?.dataArray?.[0]) {
        return responseData?.dataArray?.[0];
    } else {
      return null;
    }
  }

  setProblemTypes(data: any){
    this.problemTypes = data;
  }

  getProblemTypes() {
    return this.problemTypes;
  }

  getDraft(body: any) {
    return this.http.post<any>(RequestURL.api.getDraft, body)
      .pipe(
        map(response => this.getDataArr(response)), 
        catchError(this.exceptionHandler.handleError)
      );
  }

  get problemCategoryData(): any {
    return this._problemCategoryData;
  }

  set problemCategoryData(value: any) {
    this._problemCategoryData = value;
  }

  get draftData(): any {
    return this._draftData;
  }

  set draftData(value: any) {
    this._draftData = value;
  }

  get ticketConfirmationAlert(): any {
    return this._ticketConfirmationAlert;
  }

  set ticketConfirmationAlert(value: any) {
    this._ticketConfirmationAlert = value;
  }

  // get ticketFailureAlert(): any {
  //   return this._ticketFailureAlert;
  // }

  // set ticketFailureAlert(value: any) {
  //   this._ticketFailureAlert = value;
  // }

  mtnFormat(mtn: string) {
    let formattedMtn = mtn?.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1.$2.$3');
    return formattedMtn;
  }
}
