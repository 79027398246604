export const Constants = {
  trg: {
    trgRedirectUrl: '/mbt/secure/index?appName=trg&mdn='
  },
  cpc: {
    transactionTypes: {
      CPCLINELVEL: 'CPCLINELEVEL'
    }
  },
  notification: {
    notificationHeading: 'Notifications',
    notification: 'Notification',
    actionRequiredtitle: 'Action needed',
    generalTitle: 'Other',
    allTab: 'all',
    description1: 'new notifications require your attention',
    description2: 'new notifications',
    actionNeeded: 'actionNeeded',
    general: 'general',
    noDataFound: 'No notification found.',
    newNotifications: 'new notifications',
    expiringToday: 'notifications expiring today'
  },
  MybizHomePage: {
    esmUrl: '/mbt/secure/index?appName=esm#/esm/dashboard',
    smgmtUrl: '/mbt/secure/index?appName=smgmt&navtrk=globalnav:manage:wirelessnumbersoverviewt#/wireless-numbers/'
  },
  navItems: {
    lineAction: {
      path: '/change-billing-responsibility/line-action'
    },
    selectLine: {
      path: '/change-billing-responsibility/select-line'
    },
    confirmation: {
      path: '/change-billing-responsibility/confirmation'
    },
    punchout: {
      path: '/change-billing-responsibility/punchout'
    },
    tysLanding: {
      path: '/change-billing-responsibility/transfer-your-service'
    },
    devicePayoff: {
      path: '/mbt/secure/index?appName=mbpay#/billing/payment/device-payment'
    },
    notification: {
      path: '/mbt/secure/index?appName=esm#/notification'
    }
  },
  ATTACHMENTS: {
    LABEL_TEXT: 'Add attachments',
    ERROR_MESSAGE: 'File size should not exceed 25 MB',
    EXTENSION_TEXT: 'BMP, GIF, JPG, JPEG, PDF, PNG, XLS, XLSX, CSV, DOC, DOCx, EML and TXT file types are supported',
    FORMAT_ERROR_TEXT: 'File format not supported.',
    MAX_FILE_ERROR_TEXT: 'Maximum of 5 files can be uploaded.',
    MAX_FILE_SIZE_ERROR_TEXT: 'The total size of attachments can not exceed 25 MB',
    FILE_NAME_LENGTH_ERROR: 'The file name cannot exceed 60 characters.',
    MAX_FILE_SIZE: 25 * 1024 * 1024,
    VALID_EXTENSIONS: [
      'BMP',
      'GIF',
      'JPG',
      'JPEG',
      'PDF',
      'PNG',
      'XLS',
      'XLSX',
      'CSV',
      'DOC',
      'DOCX',
      'EML',
      'TXT',
      'TEXT'
    ],
    SPECIAL_CHARACTERS_REGEX: /[!@#$%^&*+{}[\];:'",<>?`~/\\|=]+/g
  },

  ADDITIONAL_INFO_LABELS: {
    TICKET_NUMBER: 'Ticket number',
    WIRELESS_NUMBER: 'Wireless number',
    CUSTOMER_REF: 'Customer reference',
    PROBLEM_TYPE: 'Problem type',
    CREATED_BY:'Created by',
    CREATED_ON:'Created on',
    STATUS: 'status'
  }
};
