<main role="main">
    <div>
        <div class="Grid Grid--gapless">
            <div class="homeIcon">
                <a class="back-wrap u-flexInline u-flexAlignItemsCenter pointer" (click)="redirectToLanding()">
                    <i aria-label="back" class="onedicon icon-home fs-3X u-textBold"></i>
                    <span class="pl-1X v3fontBold fs-4X lh-5X ls-05x">Home</span>
                </a>
            </div>
            <div class="Col Col--xl12 Col--lg12 Col--md12 Col--xs12 Col--sm12 u-paddingBottomNone u-paddingAllLarge ">
                <h1 class="font-44 u-marginBottomExtraSmall sm-font">Create ticket</h1>
            </div>
            <div class=" u-paddingTopNone u-paddingAllLarge">
                Pick a Category that best matches the problem you are facing
            </div>
        </div>
        <div class="Grid Grid--gapless">
            <div>
                <div role="tabpanel" aria-labelledby="tab1">
                    <div class="Grid Grid--gapless u-paddingAllLarge u-paddingTopNone">
                        <div *ngFor="let category of problemCategories"
                            class="u-paddingRightSmall u-paddingLeftNone u-paddingBottomSmall">
                            <div class="tileContainer">
                                <div class="option-container u-paddingAllLarge pointer" (click)="redirectTo(category)">
                                    <div class="textbox">
                                        <div class="font-18 bold u-paddingBottomSmall">
                                            {{category?.label}}
                                        </div>
                                        <div class="u-paddingTopExtraSmall padding-bottom w-180">
                                            {{category?.desc}}
                                        </div>
                                    </div>
                                    <div class="u-paddingTopExtraSmall">
                                        <div class=" bold font-24 u-positionAbsolute" tabindex="0"
                                            data-loc="Continue">
                                            Continue<i class="onedicon icon-right-caret bold font-14 continue_icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div>
        <app-common-tabs></app-common-tabs>
    </div> -->
</main>