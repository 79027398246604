import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WBTSService } from 'src/app/services/wbts.service';
import { MessageService } from 'primeng/api';
interface UploadEvent {
  originalEvent: Event;
  files: File[];
}
@Component({
  selector: 'app-ticket-details-modal',
  templateUrl: './ticket-details-modal.component.html',
  styleUrls: ['./ticket-details-modal.component.scss'],
})
export class TicketDetailsModalComponent {
  uploadedFiles: any[] = [];
  visible: boolean = true;
  confirmVisible: boolean = false;
  statusChangeReason: string;
  enableDetails: boolean = false;
  reopenFlag: boolean = false;
  ticketDetails: any;
  @Output() closeDetail = new EventEmitter<any>();
  public messageField = new UntypedFormControl('');
  tooltipContent =
    'Please allow 24 hours for your ticket to be worked. If you have critical issue please call 800-922-0204 in order to immediately speak with an agent regarding your ticket.';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private wbtsService: WBTSService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    // Retrieve the modal ID or any other parameters from the URL
    this.route.params.subscribe((params) => {
      const id = params['id'];
      const body = { ticketNumber: id };
      this.wbtsService.getTicketDetails(body).subscribe((response) => {
        if (response) {
          this.enableDetails = true;
          this.ticketDetails = response.dataArray[0];
          if (this.ticketDetails?.ticketStatus === 'Closed') {
            const statusDate = new Date(this.ticketDetails.ticketHistory[0]?.date);
            const next24HoursDate = this.calculateNext24Hours(statusDate);
            const currentDate = new Date();
            if (next24HoursDate > currentDate) {
              this.reopenFlag = true;
            } else {
              this.reopenFlag = false;
            }
          }
        }
      });
    });
  }

  calculateNext24Hours(date: Date): Date {
    const next24Hours = new Date(date);
    next24Hours.setDate(date.getDate() + 1);
    return next24Hours;
  }

  closeModal() {
    this.visible = false;
    this.router.navigate([{ outlets: { ticketDetails: null } }]);
  }

  public requestUpdate(id) {
    const body = { ticketNumber: id };
    this.wbtsService.requestUpdate(body).subscribe((response) => {
      if (response) {
        console.log(response);
      }
    });
  }

  onFileSelect(event: any) {
    const maxFiles = 5; 
    // this.uploadedFiles = [...this.uploadedFiles, ...event.files];
    const totalFileCount = this.ticketDetails.ticketAttachmentCount + this.uploadedFiles.length;
    if (totalFileCount > maxFiles) {
      // Remove the excess files
      // let idx = this.uploadedFiles.findIndex((p) => p.name === event.name);
      this.onRemove(event);

      // Display an error message
      alert('You can only upload a maximum of ' + maxFiles + ' files.');
    }
  }

  public closeTicket(id) {
    const body = {
      status: 'COMPLETED',
      statusChangeReason: this.statusChangeReason,
      ticketNumber: id,
    };
    this.wbtsService.closeTicket(body).subscribe((response) => {
      if (response) {
        this.confirmVisible = false;
        this.closeModal()
      }
    });
  }

  onUpload(event: UploadEvent) {
    for (let file of event.files) {
      let idx = this.uploadedFiles.findIndex((p) => p.name === file.name);
      if (idx === -1) {
        this.uploadedFiles.push(file);
        this.onFileSelect(file)
      }
      // this.uploadedFiles.push(file);
    }

    this.messageService.add({
      severity: 'info',
      summary: 'File Uploaded',
      detail: '',
    });
  }

  onRemove(event) {
    let idx = this.uploadedFiles.findIndex((p) => p.name === event.name);
    this.uploadedFiles.splice(idx, 1);
  }

  uploadFiles() {
    for (let file of this.uploadedFiles) {
      if (file instanceof File) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append(
          'ticketReferenceNumber',
          this.ticketDetails?.ticketNumber
        );
        this.wbtsService.uploadAttachment(formData).subscribe((response) => {
          if (response) {
            console.log(response);
          }
        });
      }
    }
  }
}
