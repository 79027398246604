// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const baseUrl = '/mbt/secure';
export const baseUrlUserReg = '/mbt/userregistration';

const esmPath = `${baseUrl}/esmcompositesvc/mbt/esmhome`;

export const environment = {
  production: false,
  localhost: 'http://localhost:4200',
  timer: {
    sessionExpiry: 25 * 60 * 1000,
    autoLogout: 5 * 60 * 1000
  },
  enableFraudCheck: true,
  url: {
    get_unread_msg_count: `${esmPath}/notifications/getUnReadMessageCount`,
    linksetUsers: 'sms/amsecure/manageusers/main.go#/manageusers',
  },
  recaptcha: {
    siteKey: '6LerSzIeAAAAACqwQn48dz-HDSdqZ-8t1rbXLy1J'
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
