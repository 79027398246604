import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { RequestURL } from '../../constants/url-constants';
import { catchError, map } from 'rxjs/operators';
import { IProblemDetails, IProblemType, IServiceType } from '../../interfaces';
import { Observable, BehaviorSubject } from 'rxjs';
import { ExceptionHandlerService } from '../exceptionHandler/exception-handler.service';
import { CommonService } from '../common/common.service';
import { TabConstants } from '../../constants/tab-constants';

@Injectable({
  providedIn: 'root'
})
export class TabService {

  public _ticketNumber = new BehaviorSubject<any>(null);
  private _isLocationTabRequired = new BehaviorSubject<boolean>(false);
  private _ticketInfoType = new BehaviorSubject<any>(null);
  private _ticketFailureAlert = new BehaviorSubject<any>(null);
  _tabFlow: any;
  _deviceInfo: any;
  _uploadedFiles: any;
  _problemCategoryData: any;
  originalSubProblemType: string;
  _problemInfoData: any;

  _selectedProblemType: any = {};

  _selectedServiceType: any = {};

  _selectedProblemDetails: any = {}

  _selectedLocationDetails: any = {}

  _selectedProblemCategory: any[] = [];
 
  constructor(
    private http: HttpClient, 
    private router: Router,
    private exceptionHandler: ExceptionHandlerService,
    private commonService: CommonService
  ) { }

  // Getter for selectedProblemType
  get selectedProblemType(): IProblemType {
    return this._selectedProblemType;
  }

  // Setter for selectedProblemType
  set selectedProblemType(value: IProblemType) {
    this._selectedProblemType = value;
  }

  // Getter for _selectedServiceType
  get selectedServiceType(): any {
    return this._selectedServiceType;
  }

  // Setter for _selectedServiceType
  set selectedServiceType(serviceType: IServiceType) {
    this._selectedServiceType = serviceType;
  }

  // Getter for _selectedProblemDetails
  get selectedProblemDetails(): any {
    return this._selectedProblemDetails;
  }

  // Setter for _selectedProblemDetails
  set selectedProblemDetails(value: any) {
    this._selectedProblemDetails = value;
  }

  // Getter for _selectedLocationDetails
  get selectedLocationDetails(): any {
    return this._selectedLocationDetails;
  }

  // Setter for _selectedLocationDetails
  set selectedLocationDetails(value: any) {
    this._selectedLocationDetails = value;
  }

  get ticketNumber(): any {
    return this._ticketNumber.value;
  }
  
  set ticketNumber(value: any) {
    this._ticketNumber.next(value);
  }

  get ticketInfoType(): any {
    return this._ticketInfoType.value;
  }

  set ticketInfoType(value: any) {
    this._ticketInfoType.next(value);
  }

  get isLocationTabRequired(): any {
    return this._isLocationTabRequired.asObservable();
  }

  set isLocationTabRequired(value: any) {
    this._isLocationTabRequired.next(value);
  }

  get tabFlow() {
    return this._tabFlow;
  }

  set tabFlow(flowType: any) {
    this._tabFlow = flowType;
  }

  get deviceInfo() {
    return this._deviceInfo;
  }

  set deviceInfo(data: any) {
    this._deviceInfo = data;
  }

  get ticketFailureAlert(): Observable<any> {
    return this._ticketFailureAlert.asObservable();
  }

  set ticketFailureAlert(data: any) {
    this._ticketFailureAlert.next(data);
  }

  getReviewdetails() {
    return {
      selectedProblemType: this._selectedProblemType,
      selectedServiceType: this._selectedServiceType,
      selectedProblemDetails: this._selectedProblemDetails,
      selectedLocationDetails: this._selectedLocationDetails
    };
  }

  get selectedProblemCategory() {
    return this._selectedProblemCategory;
  }

  set selectedProblemCategory(data: any) {
    this._selectedProblemCategory = data;
  }

  get uploadedFiles() {
    return this._uploadedFiles;
  }

  set uploadedFiles(data: any) {
    this._uploadedFiles = data;
  }

  get problemInfoData() {
    return this._problemInfoData;
  }

  set problemInfoData(data: any) {
    this._problemInfoData = data;
  }

  getUserDetails() {
    return this.commonService.userDetails;
  }

  getTicketNumber(body: any) {
    return this.http.post<any>(RequestURL.api.getTicketNumber, body)
      .pipe(
        map(response => this.getDataArr(response)), 
        catchError(this.exceptionHandler.handleError)
      );
  }

  getContactNumber() { 
    return this.http.post<any>(RequestURL.api.getContactNumber, undefined)
      .pipe(
        map(response => this.getDataArr(response)), 
        catchError(this.exceptionHandler.handleError)
      );
  }

  getTicketQuestions(body: any) {
    return this.http.post<any>(RequestURL.api.getTicketQuestions, body)
      .pipe(
        map(response => this.getDataArr(response)), 
        catchError(this.exceptionHandler.handleError)
      );
  }

  validateDevice(body: any) {
    return this.http.post<any>(RequestURL.api.validateDevice, body)
      .pipe(
        map(response => this.getDataArr(response)), 
        catchError(this.exceptionHandler.handleError)
      );
  }

  getTicketListAttachment(body: any) {
    return this.http.post<any>(RequestURL.api.ticketListAttachment, body)
      .pipe(
        map(response => this.getDataArr(response)), 
        catchError(this.exceptionHandler.handleError)
      );
  }

  uploadAttachment(body: any) {
    return this.http.post<any>(RequestURL.api.uploadAttachment, body)
      .pipe(
        map(response => this.getDataArr(response)), 
        catchError(this.exceptionHandler.handleError)
      );
  }

  getProblemLocationData() {
    return {
      "latitude": this._selectedLocationDetails?.latitude ? this._selectedLocationDetails?.latitude : "0.0",
      "longitude": this._selectedLocationDetails?.longitude ? this._selectedLocationDetails?.longitude : "0.0",
      "address1": this._selectedLocationDetails?.address1,
      "address2": this._selectedLocationDetails?.address2,
      "city": this._selectedLocationDetails?.city,
      "state": this._selectedLocationDetails?.state,
      "zipCode": this._selectedLocationDetails?.zipCode
    }
  }

  getContactInfo(type: any) {
    const key = type === 'primary' ? 'p' : 's';
    const name = this._selectedLocationDetails?.[key+'name']?.split(' ');
    const lastName = name?.pop();
    const firstName = name?.join(' ');

    const obj = {
      "firstName": firstName ? firstName : lastName,
      "lastName": firstName ? lastName : null,
      "email": this._selectedLocationDetails?.[key+'email'],
      "phoneNumber": this._selectedLocationDetails?.[key+'number'],
      "phoneExt": null
    }
    return obj;
  }

  getProblemQuestions() {
    const ticketQuestions = TabConstants.problemSpecific.questions[this.ticketInfoType];
    let obj = [];
    if(this.ticketInfoType === 'messagingProblemsInfo'){
      this.originalSubProblemType = 'MESSAGESEND';
      const keys = ['problemStart', 'rescentChanges', 'describeChanges', 'signalStrength', 'testMessage', 'messageIssue', 'messageDestination', 'issueExample'];
      keys.forEach((key)=> {
        obj.push({
          label: ticketQuestions[key].label,
          value: key,
          answer: this.selectedProblemDetails[key]
        });
      });
    } else if(this.ticketInfoType === 'emagProblemInfo') { 
      ticketQuestions.emagSteps.forEach((step, index)=> {
        obj.push({
          label: step.label,
          value: "emagSteps"+index,
          answer: "Confirm"
        });
      });
      const keys = [
        'functionality', 
        'information', 
        'emagProblemQuestion', 
        'primaryProtocol'
      ];
      keys.forEach((key)=> {
        obj.push({
          label: ticketQuestions[key].label,
          value: key,
          answer: this.selectedProblemDetails[key]
        });
      });
    }
    return obj;
  }

  getDeviceInfo() {
    if(this.ticketInfoType === 'activateProblemsInfo'){
      const reviewData = this.getReviewdetails();
      const identifyDevice = reviewData?.selectedServiceType;
      return {
        "validDevice": false,
        "deviceType": identifyDevice?.deviceType,
        "deviceID": identifyDevice?.deviceID,
        "simID": identifyDevice?.simID,
        "wirelessNum": identifyDevice?.wirelessNum
      }
    } else {
      return this.deviceInfo;
    }
  }

  saveTicket(openTicket: boolean) {
    const problemLoc = this.getProblemLocationData();
    const primaryDevice = this.getDeviceInfo();
    const primaryContact = this.getContactInfo('primary');
    const secondaryContact = this.getContactInfo('secondary');
    const types = this.getProblemTypeLabel();
    let requestBody = {
      "formData": {
          "problemLocation": problemLoc,
          "primaryDevice": primaryDevice,
          "primaryContact": primaryContact,
          "secondaryContact": secondaryContact,
          "fileAttachments": this.uploadedFiles,
          "ndac": false,
          "celona": false,
          "fivegSa": false,
          "fivegSaEric": false,
          "cbrsnhnpn": false,
          "nhnpwnEric": false,
          "ibcnhnEric": false,
          "ibSignalEric": false,
          "errorMessage": null,
          "problemType": TabConstants.problemCategory.value[this.selectedProblemCategory],
          "subCategory": this.selectedProblemType?.problemType,
          "subCategoryDescription": types.issueType,
          "refNum": this._selectedLocationDetails?.refNo,
          "problemQuestions": this.problemInfoData,
          "accountNumber": primaryDevice?.accountNumber,
          "originalProblemType": null,
          "originalSubProblemType": this.originalSubProblemType,
          "wfmOriginalStatus": null,
          "accountRefNum": null,
          "autoRefFlag": false,
          "bjMeetingId": null,
          "refrenceAccountNo": primaryDevice?.accountNumber
      },
      "ticketNumber": this.ticketNumber,
      "accountNumber": primaryDevice.accountNumber,
      "openTicket": openTicket
    }

    return this.http.post<any>(RequestURL.api.saveTicket, requestBody)
      .pipe(
        map(response => this.getDataArr(response)), 
        catchError(this.exceptionHandler.handleError)
      );
  }

  

  closeTicket() {
    let req = {
      "ticketNumber": this.ticketNumber,
      "status": "CANCELLED",
      "statusChangeReason": "CANCELLED"
    }

    this.changeStatus(req).subscribe((response: any) => {
      if(response?.status === "Success") {
        this.commonService.ticketConfirmationAlert = {ticketNumber: this.ticketNumber};
        this.clearTabData();
      } else {
        this.scrollToTop();
        this.ticketFailureAlert = {flow: "close"};
      }
    },
    (error: any) => {
      this.scrollToTop();
      this.ticketFailureAlert = {flow: "close"};
    });
  }

  changeStatus(requestBody: any) {
    return this.http.post<any>(RequestURL.api.changeStatus, requestBody)
    .pipe(
      map(response => this.getDataArr(response)), 
      catchError(this.exceptionHandler.handleError)
    );
  }

  getProblemTypeLabel(){
    let selectedIssueTypeLabel;
    const problemInfo = this.getProblemCategoryData();
    const ticketCategoryKey = TabConstants.problemCategory.value[this.selectedProblemCategory];
    
    if(problemInfo[ticketCategoryKey]?.problemTypes){
      problemInfo[ticketCategoryKey]?.problemTypes.forEach((problemType)=>{
        if(problemType?.value === this.selectedProblemType?.problemType){
          problemType?.issueTypes.forEach(issueType => {
            if(issueType?.value === this.selectedProblemType?.issueType){
              selectedIssueTypeLabel = {
                problemType: problemType?.label,
                issueType: issueType?.label
              }
            }
          });
        }
      });
    } 

    else if(!problemInfo[ticketCategoryKey]?.problemTypes && problemInfo[ticketCategoryKey]?.issueTypes){
      problemInfo[ticketCategoryKey]?.issueTypes.forEach(issueType => {
        if(issueType?.value === this.selectedProblemType?.issueType){
          selectedIssueTypeLabel = {
            problemType: null,
            issueType: issueType?.label
          }
        }
      });
    }
    
    return selectedIssueTypeLabel;
  }

  isEmptyObject(obj: any): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  getDataArr(responseData) {
    return this.commonService.getDataArr(responseData);
  } 

  getProblemRequestObject() {
    const ticketCategoryKey = TabConstants.problemCategory.value[this.selectedProblemCategory];
    const ticketTypes = this.selectedProblemType;
    return {
      problemType: ticketCategoryKey,
      subCategory: ticketTypes?.['problemType'] ? ticketTypes?.['problemType'] : ticketTypes?.['issueType']
    }
  }

  updateTicketInfo(data: any) {
    this.isLocationTabRequired = false;
    if (data.problemType === 'dataNetworkProblems') {
      this.ticketInfoType = 'dataNetworkProblemsInfo';
      this.isLocationTabRequired = true;
    } else if (data.problemType === 'activateDevSim') {
      this.ticketInfoType = 'activateProblemsInfo';
      this.isLocationTabRequired = true;
    } else if (data.problemType === 'phoneCallProblems') {
      this.ticketInfoType = 'phoneCallProblemsInfo';
      this.isLocationTabRequired = true;
    } else if (data.problemType === 'iotProblems') {
      this.ticketInfoType = 'iotProblemsInfo';
    } else if(data.problemType === 'messagingProblems') {
      if(data.subCategory === 'emagSetupIssues') {
        this.ticketInfoType = 'emagProblemInfo';
      } else {
        this.ticketInfoType = 'messagingProblemsInfo';
        this.isLocationTabRequired = true;
      }
    } else if (data.problemType === 'onsite_LTE' || data.problemType === 'inBuildingCoverage') {
      if(data.subCategory === 'PERFORMANCE') {
        this.ticketInfoType = 'onSiteLTEPerformanceInfo';
        this.isLocationTabRequired = true;
      } else if(data.subCategory === 'CONNECTIVITY') {
        this.ticketInfoType = 'onSiteLTEConnectivityInfo';
        this.isLocationTabRequired = true;
      } else if(data.subCategory === 'NETWORKDASHBOARD' || data.subCategory === 'CORNINGNHDAS'){
        this.ticketInfoType = 'onSiteLTENetworkDashboardInfo';
      }
    }
  }

  getProblemCategoryData() {
    return this.commonService.problemCategoryData;
  }

  formatDevice(identityType: string, deviceId: string) {
    const regex = TabConstants.validateDevice.Regex;
    const problemType = this.selectedProblemType?.problemType;

    if (deviceId) {
      if (identityType != "email") {
        deviceId = deviceId.replace(/[-(). ]/g, "");
      }
      switch (identityType) {
        case "wirelessNum":
          return regex.mdnRegex.test(deviceId);
        case "deviceID":
          return regex.deviceIdRegex.test(deviceId);
        case "simID":
          // if(problemType === 'activateProblems'){
          // 	if($scope.formData.primaryDevice.deviceType === '4G'){
          // 		return iccidRegex.test(deviceId);
          // 	}else{
          // 		return true;
          // 	}
          // }else{
          return regex.iccidRegex.test(deviceId);
        // }
        case "email":
          return regex.emailRegex.test(deviceId);
      }
    }
    return false;
  }

  clearTabData() {
    this.ticketNumber = null;
    this.selectedProblemType = null;
    this.selectedServiceType = null;
    this.selectedProblemDetails = null;
    this.selectedLocationDetails = null;
    this.selectedProblemCategory = [];
  }

  redirectTo(page: any) {
    const route = RequestURL?.routing?.[page];
    this.router.navigate([route]);
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}
