export const TabConstants = {
    problemCategory: {
        title: {
            messaging: "Messaging",
            phonecalls: "Phone Calls",
            dataconnection: "Data Connection",
            onsitelte5g: "On-Site LTE/5G",
            singledeviceactivation: "Single Device Activation",
            thingspace: "Thingspace"
        },
        value: {
            messaging: "messagingProblems",
            phonecalls: "phoneCallProblems",
            dataconnection: "dataNetworkProblems",
            onsitelte5g: "onsite_LTE",
            singledeviceactivation: "activateDevSim",
            thingspace: "iotProblems",
            inbuildingcoverage: "inBuildingCoverage"
        }
    },
    title: {
        problemType: 'Select Problem Type',
        identifyService: 'Identify services',
        problemSpecifics: 'Add problem specifics',
        locateService: 'Locate services',
        reviewAndSubmit: 'Review and submit'
    },
    validateDevice: {
        Regex: {
            mdnRegex: /^[0-9]{10}$/,
            iccidRegex: /^[0-9]{20}$/,
            deviceIdRegex: /(^[a-fA-F0-9]{14}$|^[0-9]{15}$|^[0-9]{16}$|^[0-9]{15}$|^[0-9]{11})/,
            emailRegex:  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        }
    },
    locateService: {
        Regex: {
            emailRegex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            phnoneRegex: /^\d{10}$/
        },
        errorMsg: {
            default: "Please check the validation errors.",
            invalidEmail: "Please enter a valid email address.",
            invalidPhone: "Please enter a valid phone number.",
            sameContactInfo: "Primary and secondary contact information cannot be same."
        }
    },
    reviewAndSubmit: {
        problemType: {
            title: "Problem type",
            type: "Type",
            subType: 'Sub type'
        },
        identifyService: {
            title: "Identified services",
            serviceTypeLabels: {
                wirelessNum: 'Wireless number',
                deviceID: 'Device ID',
                simID: 'SIM ID'
            }
        },
        problemSpecifics: {
            title: "Problem specifics",
            messagingProblemsInfo: {
                fields: {
                    problemDescription: "Problem description",
                    troubleSending: "Troble sending messages from these numbers in the past?",
                    recentChange: "Device or messaging app changed recently?",
                    signalStrength: "Signal strength",
                    sendingMessageToMDN: "Sending message to own MDN worked?",
                    problemStartDate: "Problem start date"
                }
            },
            activateProblemsInfo: {
                fields: {
                    activationSuccess: "Was activation successful?",
                    describeActivation: "Please describe what happens when you attempt activation. Do you receive any specific error messages?"
                }
            },
            emagProblemsInfo: {
                fields: {
                    functionality: "What functionality are you attempting to setup?",
                    protocal: "Which primary protocol are you trying to set up?",
                    resolved: "Did this information resolve your issue?",
                    resolvedDesc: "Please describe the problem or question that you are having. One of our EMAG specialists will contact you as soon as possible."
                }
            },
            phoneCallProblemsInfo: {
                fields: {
                    errorMessage: "Please describe the user experience, including any error messages you receive.",
                    makeReceiveCalls: "Have you been able to make/receive calls at this location before?",
                    located: "Where are you located?",
                    signalStrength: "Please describe your signal strength.",
                    location: "Tell me about your location?",
                    problemConsistent: "Is the problem consistent, or does it come and go?",
                    specificPhoneNum: "Does the problem only occur with a specific phone number?",
                    phoneNumberDesc: "What is the phone number?",
                    receiveCall: "When were you last able to receive a call?",
                    problemStart: "When did the problem start?"
                }
            },
            dataNetworkProblemsInfo: {
                fields: {
                    errorReceiving: "Please describe the user experience, including any error messages you receive.",
                    networkChange: "Have you recently made any network changes?",
                    specificApp: "Are you having issues with a specific application or website?",
                    whatAppOrSite: "What is the website or application you are attempting to reach?",
                    unitsDeployed: "Where is (are) the unit(s) deployed?",
                    locationFixedOrMobile: "Is the device deployed at a stationary location or does it move around?",
                    changesMade: "What changes were made?",
                    additionalInfo: "Any additional information?",
                    problemStart: "When did the problem start?",
                    lastConnect: "When were you last able to connect?"
                }
            },
            iotProblemsInfo: {
                fields: {
                    errorReceiving: "What is the exact error message(s) that you received?",
                    problemStart: "Please provide the date and time when the provisioning request was submitted.",
                    internalRefNum: "Customer reference",
                    additionalInfo: "Describe the problem you are encountering.",
                    functionality: "What type of provisioning activity are you attempting?",
                    information: "Did the information in Developer Wiki resolve your issue?",
                    uwsUsername: "Please confirm your UWS user name.",
                    uwsCallback: "Please confirm your call back URL (if applicable)",
                }
            },
            onSiteLTEPerformanceInfo: {
                fields: {
                    problemStart: "When did the problem start?",
                    lastConnect: "When were you last able to connect?",
                    networkChange: "Have you recently made any network changes?",
                    specificApp: "Are you having issues with a specific application or website?",
                    locationFixedOrMobile: "Is the device deployed at a stationary location or does it move around?",
                    signalStrength: "Please describe your signal strength.",
                    problemConsistent: "Is the problem consistent, or does it come and go?",
                    powerOutage: "Was there any power outage ?",
                    troubleshoot: "Describe trouble/issue in detail - any troubleshooting steps taken, any alarms",
                    whatAppOrSite: "What is the website or application you are attempting to reach?",
                    internalRefNum: "Customer reference",
                    additionalInfo: "Any additional information?",
                    physicalDamage: "Any physical signs of  damage to the device (or exposure to undue environmental conditions)?",
                    degradedLocations: "Explain multiple locations where performance is degraded. ie..floor, room, building, inside, outside etc",
                }
            },
            onSiteLTEDashboardInfo: {
                fields: {
                    problemStart: "When did the problem start?",
                    lastConnect: "When were you last able to use the dashboard functionality?",
                    troubleshoot: "Describe trouble/issue in detail - any troubleshooting steps taken.",
                    frequency: "Frequency of the problem.",
                    specificDashboard: "Issue with any particular application on the dashboard?",
                    affectedUsers: "Is this effecting",
                    error: "Did you receive an error message",
                    simProvisioning: "Issue with SIM provisioning issue?",
                    affectedSim: "Is this effecting",
                    affectedIccid: "List all effected ICCIDs",
                    errorDashboard: "Did you receive an error message on the device or dashboard",
                    additionalInfo: "Any additional information",
                    refNo: "Customer reference number"
                }
            }
        },
        additionalDetails: {
            title: "Additional details",
            fields: {
                address: "Address",
                coordinates: "Coordinates",
                map: "Map",
                primartContact: "Primary contact",
                secondaryContact: "Secondary contact",
                customerReferenceNumber: "Customer reference number",
                attachments: "Attachments"
            }
        }
    },
    categoryDesc: {
        Messaging: "Use this problem type to report issues sending and/or receiving texts, pictures and video messages on your mobile device/establishing Enterprise Messaging (EMAG) campaigns. ",
        PhoneCalls: "Use this problem type to report issues making and / or receiving phone calls on a mobile device. Have the device ID, phone number, SIM card and its location handy.",
        DataConnection: "Use this problem type to report issues connecting to the Internet or private network.",
        InBuildingCoverage: "Use this problem type to report issues related to In-Building Coverage solutions (including CBRSNHN: CBRS Neutral Host Network & Neutral Host Distributed Antenna System). Report issues such as individual or group of devices experiencing problems connecting or maintain connections, or performance quality issues around throughput or latency.",
        OnSiteLTE5G: "Use this problem type to report issues specific to On Site LTE/5G installation, such as individual or groups of devices experiencing problems connecting or maintaining connections, or performance quality issues around throughput or latency.",
        SingleDeviceActivation: "Use this problem type to report issues activating new device with existing wireless line. Have the device ID, phone number and SIM card handy.",
        Thingspace: "Use this problem type to report issues related to Machine to Machine or Internet of Things (IoT) devices, or with bulk device activation."
    },
    problemSpecific: {
        questions: {
            messagingProblemsInfo: {
                describeChanges: {
                    "label": "Problem description",
                    "value": "describeChanges"
                },
                testMessage: {
                    "label": "Did it work when you tried sending a message to your own MDN?",
                    "value": "testMessage"
                },
                signalStrength: {
                    "label": "How strong was the signal",
                    "value": "signalStrength"
                },
                messageDestination: {
                    "label": "Did you have trouble sending messages from these numbers in the past?",
                    "value": "messageDestination"
                },
                issueExample: {
                    "label": "Help us with the numbers to which you were able to send messages.",
                    "value": "issueExample"
                },
                rescentChanges: {
                    "label": "Did you recently replace your device or switch to another messaging app?",
                    "value": "rescentChanges"
                },
                messageIssue: {
                    "label": "Tell us about the changes.",
                    "value": "messageIssue"
                },
                problemStart: {
                    "label": "When did the problem start?",
                    "value": "problemStart"
                }
            },
            emagProblemInfo: {
                "emagSteps": [
                    {
                        "label": "Have you started the EMAG enrollment process?"
                    },
                    {
                        "label": "Have you engaged your local Verizon Sales associate?"
                    },
                    {
                        "label": "Have you completed and submitted your EMAG data sheet?"
                    },
                    {
                        "label": "Have you received your Pilot MDN and Pilot Device?"
                    },
                    {
                        "label": "Did you activate them?"
                    },
                    {
                        "label": "Have you registered your Pilot MDN on MyBusiness?"
                    },
                    {
                        "label": "Have you logged into the EMAG Portal to set up your EMAG profile?"
                    }
                ],
                "functionality": {
                    "label": "What functionality are you attempting to setup?",
                    "value": "functionality"
                },
                "apiFunctionality": {
                    "label": "Setting up my API protocol to send messages."
                },
                "emagFunctionality": {
                    "label": "Setting up EMAG via Enterprise Messaging Portal."
                },
                "information": {
                    "label": "Did this information resolve your issue?",
                    "value": "information"
                },
                "emagProblemQuestion": {
                    "label": "Please describe the problem or question that you are having. One of our EMAG specialists will contact you as soon as possible.",
                    "value": "emagProblemQuestion"
                },
                "primaryProtocol": {
                    "label": "Which primary protocol are you trying to set up?",
                    "value": "primaryProtocol"
                }
            },
            "phoneCallProblemsInfo": {
                "problemStart": {
                    "label": "When did the problem start?",
                    "value": "problemStart"
                },
                "internalRefNum": {
                    "label": "Customer reference"
                },
                "fileAttachments": {
                    "label": "Please attach any file that should go with this ticket"
                },
                "problemLocation": {
                    "label": "Problem location"
                },
                "signalStrength": {
                    "label": "Please describe your signal strength.",
                    "value": "signalStrength"
                },
                "receiveCall": {
                    "label": " When were you last able to receive a call?"
                },
                "location": {
                    "label": "Tell me about your location?",
                    "value": "location"
                },
                "located": {
                    "label": "Where are you located?",
                    "value": "located"
                },
                "makeReceiveCalls": {
                    "label": "Have you been able to make/receive calls at this location before?",
                    "value": "makeReceiveCalls"
                },
                "problemConsistent": {
                    "label": "Is the problem consistent, or does it come and go?",
                    "value": "problemConsistent"
                },
                "specificPhoneNum": {
                    "label": "Does the problem only occur with a specific phone number?",
                    "value": "specificPhoneNum"
                },
                "phoneNumber": {
                    "label": "What is the phone number?"
                },
                "advancedCalling": {
                    "label": "Do you have advanced calling turned on?",
                    "value": "advancedCalling"
                },
                "makeOrReceiveCalls": {
                    "label": "When were you last able to receive or make a call?",
                    "value": "makeOrReceiveCalls"
                },
                "errorMessage": {
                    "label": "Please describe the user experience, including any error messages you receive.",
                    "value": "errorMessage"
                }
            },
            dataNetworkProblemsInfo: {
                "errorReceiving": {
                    "label": "Please describe the user experience, including any error messages you receive."
                },
                "problemStart": {
                    "label": "When did the problem start?"
                },
                "lastConnect": {
                    "label": "When were you last able to connect?"
                },
                "networkChange": {
                    "label": "Have you recently made any network changes?"
                },
                "changesMade": {
                    "label": "What changes were made?"
                },
                "negativeImpact": {
                    "label": "Have you confirmed those changes are not negatively impacting your network?"
                },
                "specificApp": {
                    "label": "Are you having issues with a specific application or website?"
                },
                "whatAppOrSite": {
                    "label": "What is the website or application you are attempting to reach?"
                },
                "unitsDeployed": {
                    "label": "Where is (are) the unit(s) deployed?"
                },
                "locationFixedOrMobile": {
                    "label": "Is the device deployed at a stationary location or does it move around?"
                },
                "additionalInfo": {
                    "label": "Any additional information?"
                }
            }, 
            activateProblemsInfo: {
                "activationSteps3G": [
                    {
                        "label": "Please confirm that the Wireless Number and Device ID matches the information on your order. Below is the information you provided in the previous step:"
                    },
                    {
                        "label": "Perform a soft reset (power cycle or pull battery) for smartphones, tablets and hotspots. If the device is a modem, reboot the modem."
                    },
                    {
                        "label": "Ensure that you have a good wireless signal; if not you may need to change your location. Check coverage"
                    },
                    {
                        "label": "Dial *228 or trigger the activation command depending on the device."
                    },
                    {
                        "label": "Allow up to 5 minutes for activation to complete."
                    }
                ],
                "activationSteps4G": [
                    {
                        "label": "Please confirm that the Wireless Number, Device ID, and 4G SIM ICCID matches the information on your order. Below is the information you provided in the previous step:"
                    },
                    {
                        "label": "Please confirm that the SIM card is inserted properly in the device."
                    },
                    {
                        "label": "Ensure that you have a good wireless signal; if not you may need to change your location. Check coverage"
                    },
                    {
                        "label": "Perform a soft reset (power cycle or pull battery) for smartphones, tablets and hotspots. If the device is a modem, reboot the modem."
                    },
                    {
                        "label": "Allow up to 5 minutes for activation to complete."
                    }
                ],
                "activationSuccess": {
                    "label": "Was activation successful?"
                },
                "describeActivation": {
                    "label": "Please describe what happens when you attempt activation. Do you receive any specific error messages?"
                }
            },
            iotProblemsInfo: {
                "errorReceiving": {
                    "label": "What is the exact error message(s) that you received?"
                },
                "problemStart": {
                    "label": "Please provide the date and time when the provisioning request was submitted."
                },
                "additionalInfo": {
                    "label": "Describe the problem you are encountering."
                },
                "functionality": {
                    "label": "What type of provisioning activity are you attempting?"
                },
                "information": {
                    "label": "Did the information in Developer Wiki resolve your issue?"
                },
                "uwsUsername": {
                    "label": "Please confirm your UWS user name."
                },
                "uwsCallback": {
                    "label": "Please confirm your call back URL (if applicable)"
                },
                "problemStartTime": {
                    "label": "Please provide the time when the provisioning request was submitted."
                }
            },
            onSiteLTEPerformanceInfo: {
                "problemStart": {
                    "label": "When did the problem start?"
                },
                "lastConnect": {
                    "label": "When were you last able to connect?"
                },
                "networkChange": {
                    "label": "Have you recently made any network changes?"
                },
                "specificApp": {
                    "label": "Are you having issues with a specific application or website?"
                },
                "whatAppOrSite": {
                    "label": "What is the website or application you are attempting to reach?"
                },
                "locationFixedOrMobile": {
                    "label": "Is the device deployed at a stationary location or does it move around?"
                },
                "additionalInfo": {
                    "label": "Any additional information?"
                },
                "signalStrength": {
                    "label": "Please describe your signal strength."
                },
                "problemConsistent": {
                    "label": "Is the problem consistent, or does it come and go?"
                },
                "powerOutage": {
                    "label": "Was there any power outage ?"
                },
                "physicalDamage": {
                    "label": "Any physical signs of  damage to the device (or exposure to undue environmental conditions)?"
                },
                "degradedLocations": {
                    "label": "Explain multiple locations where performance is degraded. ie..floor, room, building, inside, outside etc"
                },
                "troubleshoot": {
                    "label": "Describe trouble/issue in detail - any troubleshooting steps taken, any alarms"
                },
                "speedTest": {
                    "label": " Attach traces, logs etc.Please run a speed test  https://www.verizon.com/speedtest/.(capture the screenshot and attach the file *.jpeg or png)"
                }
            },
            onSiteLTEConnectivityInfo: {
                "problemStart": {
                    "label": "When did the problem start?"
                },
                "lastConnect": {
                    "label": "When were you last able to connect?"
                },
                "networkChange": {
                    "label": "Have you recently made any network changes?"
                },
                "specificApp": {
                    "label": "Are you having issues with a specific application or website?"
                },
                "whatAppOrSite": {
                    "label": "What is the website or application you are attempting to reach?"
                },
                "locationFixedOrMobile": {
                    "label": "Is the device deployed at a stationary location or does it move around?"
                },
                "internalRefNum": {
                    "label": "Customer reference"
                },
                "additionalInfo": {
                    "label": "Any additional information?"
                },
                "signalStrength": {
                    "label": "Please describe your signal strength."
                },
                "problemConsistent": {
                    "label": "Is the problem consistent, or does it come and go?"
                },
                "powerOutage": {
                    "label": "Was there any power outage ?"
                },
                "degradedLocations": {
                    "label": "Explain multiple locations where performance is degraded. ie..floor, room, building, inside, outside etc"
                },
                "troubleshoot": {
                    "label": "Describe trouble/issue in detail - any troubleshooting steps taken, any alarms"
                },
                "speedTest": {
                    "label": " Attach traces, logs etc.(capture the screenshot and attach the file *.jpeg or png)"
                }
            },
            onSiteLTENetworkDashboardInfo: {
                "problemStart": {
                    "label": "When did the problem start?"
                },
                "lastConnect": {
                    "label": "When were you last able to use the dashboard functionality?"
                },
                "additionalInfo": {
                    "label": "Any additional information "
                },
                "troubleshoot": {
                    "label": "Describe trouble/issue in detail - any troubleshooting steps taken."
                },
                "frequency": {
                    "label": "Frequency of the problem. "
                },
                "specificDashboard": {
                    "label": "Issue with any particular application on the dashboard? "
                },
                "affectedUsers": {
                    "label": "Is this effecting "
                },
                "error": {
                    "label": "Did you receive an error message "
                },
                "simProvisioning": {
                    "label": "Issue with SIM provisioning issue? "
                },
                "affectedSim": {
                    "label": "Is this effecting "
                },
                "affectedIccid": {
                    "label": "List all effected ICCIDs "
                },
                "errorDashboard": {
                    "label": "Did you receive an error message on the device or dashboard "
                },
                "dashboardScreenshot": {
                    "label": "Attach (capture the screenshot and attach the file *.jpeg or png) "
                }
            }
        }
    }

}