'use strict';

export const asset_path = '/trgstatic/assets';
export const aem_homepage_base_template_path = '/content/my-business-portal/Home_Template/';
export const aem_pod_base_template_path = '/content/my-business-portal/HomepageCurrentProduction/';
export const aem_commerce_base_template_path = '/content/my-business-portal/test-blank/';

export const aem_error_card = '/data/api/aem_error_card.json';

export function getAemApiKeys(): string[] {
  const aemKeyStore: string[] = [];
  aemKeyStore.push('commerce-footer');
  return aemKeyStore;
}

export function aemApiPath(aemContextPath: string, key: string): string {
  return aemContextPath + key + '.api';
}

export function getGlobalServerInfo(): any {
  return window['SERVER_INFO'];
}
