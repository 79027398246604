<div
  *ngIf="showLoader"
  class="loader-container"
  [attr.aria-busy]="showLoader"
  [attr.aria-live]="showLoader ? 'polite' : 'off'"
  aria-label="loading"
>
  <div class="font-20 u-textBold u-textCenter u-paddingAllMedium loader">
    <span class="Icon Icon--loader"></span>
    <div>Please wait</div>
  </div>
</div>
