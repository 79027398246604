import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { ProblemCategoryComponent } from './components/problemCategory/problem-category.component';
import { SharedModule } from '../app/shared/shared.module';
// import { RequestInterceptor } from './shared/interceptors/request.interceptor';
// import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';
import { CommonService } from './services/common/common.service';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    ProblemCategoryComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
      CommonService, DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
