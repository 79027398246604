import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FooterComponent } from './layout/secure-layout/footer/footer.component';
import { HeaderComponent } from './layout/secure-layout/header/header.component';
import { SharedModule } from '../shared/shared.module';
import { LoaderComponent } from '../shared/components/loader/loader.component';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, LoaderComponent],
  imports: [CommonModule, SharedModule, HttpClientModule],
  exports: [HeaderComponent, FooterComponent, LoaderComponent]
})
export class CoreModule {}
