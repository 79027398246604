// export const API_BASE_PATH = window.location.hostname === 'localhost' ? 'http://localhost:3000' : '/ticketing/amsecure';
const API_BASE_PATH = '/ticketing/amsecure';

let url = {
    api: {
        entryInfo: API_BASE_PATH + '/login/entryInfo',
        getTicketList: API_BASE_PATH + '/landing/getTicketingList',
        getTicketCategories: API_BASE_PATH + '/createTicket/getTicketCategories',
        getTicketNumber: API_BASE_PATH + '/createTicket/getTicketNumber',
        getContactNumber: API_BASE_PATH + '/createTicket/getContactNumber',
        getTicketQuestions: API_BASE_PATH + '/createTicket/getTicketQuestions',
        validateDevice: API_BASE_PATH + '/details/validateDevice',
        getDraft: API_BASE_PATH + '/createTicket/getDraft',
        ticketListAttachment: API_BASE_PATH + '/attachment/ticketListAttachment',
        uploadAttachment: API_BASE_PATH + '/attachment/uploadAttachment',
        saveTicket: API_BASE_PATH + '/createTicket/save',
        changeStatus: API_BASE_PATH + '/details/changeStatus'
    },
    routing:{
        landing:"/landing",
        createTicket:"/createTicket",
        problemCategory:"/problemCategory",
        editTicket: "/editTicket",
    },
    local: {
        entryInfo: API_BASE_PATH + '/entryInfo',
        getTicketList: API_BASE_PATH + '/getTicketingList',
        getTicketCategories: API_BASE_PATH + '/getTicketCategories',
        validateDevice: API_BASE_PATH + '/validateDevice',
    }
};

if (API_BASE_PATH.indexOf('localhost') > 0) {
    // url.api = url.local
}

export const RequestURL = url;
