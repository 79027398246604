import { Injectable } from '@angular/core';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})
export class MVBWebViewService {
  isMVBRequest = false;
  isIOSRequest = false;
  isAndriodRequest = false;
  osName = '';
  constructor(private cookieService: CookieService) {
    const requestCookie = this.cookieService.getLocalCookie('X-VZ-C_APP');
    const osCookie = this.cookieService.getLocalCookie('X-VZ-C_CLIENT');
    if (requestCookie != undefined && requestCookie != null && requestCookie.match('MVB')) {
      this.isMVBRequest = true;
    }
    if (osCookie != undefined && osCookie != null && osCookie.match('IOS')) {
      this.isIOSRequest = true;
      this.osName = 'IOS';
    } else if (osCookie != undefined && osCookie != null && osCookie.match('ANDROID')) {
      this.isAndriodRequest = true;
      this.osName = 'ANDROID';
    }
  }

  invokeNativeFromWebView(message: string) {
    console.log('Invoke Native called');
    console.log('Message: ' + message);
    const webWindow = window as any;
    if (
      webWindow.webkit &&
      webWindow.webkit.messageHandlers &&
      webWindow.webkit.messageHandlers.mfAppInterface &&
      webWindow.webkit.messageHandlers.mfAppInterface !== undefined
    ) {
      webWindow.webkit.messageHandlers.mfAppInterface.postMessage(message);
    }
    if (webWindow.mfAppInterface && webWindow.mfAppInterface !== undefined) {
      webWindow.mfAppInterface.postMessage(message);
    }
  }

  mvBCancel() {
    const cancelhandshake = {
      actionType: 'registration',
      txnName: 'cancel',
      status: 'success',
      desc: ''
    };
    this.invokeNativeFromWebView(JSON.stringify(cancelhandshake));
  }
  handShakeFailure(txnName: string) {
    const handShakeData = {
      actionType: 'registration',
      txnName,
      status: 'failure',
      desc: ''
    };
    this.invokeNativeFromWebView(JSON.stringify(handShakeData));
    return;
  }
}
