import { Component } from '@angular/core';
import { CookieService } from '../../../../shared/services/cookie.service';
import { MVBWebViewService } from '../../../../shared/services/mvb-webview.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [MVBWebViewService, CookieService]
})
export class HeaderComponent {
  public isMVB = false;

  constructor(private mvbWebViewService: MVBWebViewService) {
    //Check if the MVB cookie exists in the request. If yes, hide the header for WebView
    this.isMVB = this.mvbWebViewService.isMVBRequest;
  }
}
