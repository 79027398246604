import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
// import * as sharedGlobal from '../utils/shared.global';

@Injectable()
export class InternalMessagingService {
  constructor(private http: HttpClient) {}

  fetchUnreadMessagesCount(request: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        AM_ECPD_ID: request?.ecpdId,
        SEGMENT: request?.segment
      })
    };
    return this.http.get(environment.url.get_unread_msg_count, httpOptions);
  }
}
