import { Component } from '@angular/core';
import { TabConstants } from 'src/app/constants/tab-constants';
import { IProblemCategory } from "src/app/interfaces";
import { CommonService } from 'src/app/services/common/common.service';
import { TabService } from 'src/app/services/tab/tab.service';
import { Router } from '@angular/router';
import { RequestURL } from 'src/app/constants/url-constants';

@Component({
  selector: 'app-problem-category',
  templateUrl: './problem-category.component.html',
  styleUrls: ['./problem-category.component.scss']
})
export class ProblemCategoryComponent {
  
  constructor(
    private router: Router, 
    private commonService: CommonService,
    private tabService: TabService
  ) {}

  public problemCategories: IProblemCategory[] = [];
  public categoryDesc = TabConstants?.categoryDesc;

  ngOnInit(): void {
    this.tabService.ticketNumber = null;

    this.getProblemCategory();
  }

  public getProblemCategory() {
    this.commonService.getProblemCategory().subscribe((data) => {
      if(data) {
        // sessionStorage.setItem('ticketCategories', JSON.stringify(data));
        this.commonService.problemCategoryData = data;
        for (const key in data) {
          if (data.hasOwnProperty(key) && key !== "bj") {
            const obj = {
              label: data[key].label,
              value: data[key].value,
              desc: this.categoryDesc[this.removeSpace(data[key].label)]
            };
        
            // Find the correct position to insert the obj to maintain alphabetical order
            let insertIndex = this.problemCategories.findIndex(category => category.label.localeCompare(obj.label) > 0);
        
            // If no such position is found, insert at the end
            if (insertIndex === -1) {
              insertIndex = this.problemCategories.length;
            }
        
            // Insert the obj at the correct position
            this.problemCategories.splice(insertIndex, 0, obj);
          }
        }
      }
    });
  }

  removeSpace(input: string): string {
    return input.replace(/[\s\/-]/g, '');
  }

  redirectTo(problemType: any) {
    const route = RequestURL?.routing?.createTicket;
    const queryParams = {
      problemType: this.removeSpace(problemType.label).toLocaleLowerCase()
    }
    this.router.navigate([route], {queryParams});
  }

  redirectToLanding() {
    const route = RequestURL?.routing?.landing;
    this.router.navigate([route]);
  }
}
