import { HttpParams, HttpResponse } from '@angular/common/http';
import { ElementRef, Renderer2 } from '@angular/core';

export class Helper {
  static dataUnits = { KB: 'Kilobyte', MB: 'Megabyte', GB: 'Gigabyte' };
  static userIdRegex = /^[a-zA-Z0-9\\_\\.@\\-]{6,60}$/;
  static userIdRegex2 = /^(?=.*[a-zA-Z])([a-zA-Z0-9\\_\\.@\\-]){6,60}$/;
  static nameRegex = /^[\sa-zA-Z]+([-_.' ]+[\sa-zA-Z]+)*$/;
  static phoneRegex = /^[0-9-_() .]{10,14}$/;
  static onlyTextdata = /^[a-zA-Z ]*$/;
  static phoneRegex3 = /^[2-9]{1}[0-9]{2}[.]?[0-9]{3}[.]?[0-9]{4}$/;
  static repeatativeNumber = /^([0-9])\1*$/;
  static currencyRegex = /^([1-9][0-9]*)+(\.[0-9]{1,})?$/;
  static emailRegex = /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/;
  static emailRegex2 =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static wirelessNoRegex = /[0-9]{3}.[0-9]{3}.[0-9]{4}/;
  static domainRegex = /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;
  static ssoDomainRegEx = /^(?!\\d)[a-zA-Z]+[a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;
  static mtnNo = /\d{3}-\d{3}-\d{4}/;
  static alphaNumeric = /^[\d\w\s-]*[\da-zA-Z]+[\d\w\s-]*$/;
  static ssoEndpointRegx = /(https:\/\/)[a-zA-Z0-9\/]{1,}/;
  static customRoleNameRegx = /(CUS-)[a-zA-Z0-9]{1,}/;
  static namePattern = /^([a-zA-Z-_.' ]+){1,}$/;
  static phonePattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
  static emailPattern =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@((\\\\[[0-9]{1,3}\\\\.[0-9]{1,3}\\\\.[0-9]{1,3}\\\\.[0-9]{1,3}\\\\])|(([a-zA-Z\\\\0-9]+\\\\.)+[a-zA-Z]{2,}))$/;
  static transactionType = {
    'company-users/add': 'addCompanyUser',
    'company-users/edit': 'editCompanyUser',
    'linkset-users/add': 'addLinksetUser',
    'linkset-users/edit': 'editLinksetUser'
  };

  static isLinksetUser(): boolean {
    return window['IS_MERGED_USER'] || 'false';
  }

  static mbtRedirect(path: string) {
    const url = `${Helper.getAppHost()}/${path}`;
    window.location.assign(url);
  }

  static redirect(path: string, epb?: boolean) {
    const url = Helper.getURL(path, epb);
    window.location.assign(url);
  }

  static getURL(path: string, epb?: boolean): string {
    let host = window['SERVER_INFO'].mbBaseHost;
    if (epb) {
      host = host.replace('b2b', 'epb');
    }
    return `${host}/${path}`;
  }

  static updateURL(url = ''): string {
    if (url.indexOf('{{MBT_URL}}') >= 0) {
      url = url.replace('{{MBT_URL}}', window['SERVER_INFO'].appHost);
    } else if (url.indexOf('{{B2B_URL}}') >= 0) {
      url = url.replace('{{B2B_URL}}', window['SERVER_INFO'].mbBaseHost);
    } else if (url.indexOf('{{EPB_URL}}') >= 0) {
      url = url.replace('{{EPB_URL}}', window['SERVER_INFO'].mbBaseHost.replace('b2b', 'epb'));
    }
    return url;
  }

  static getAppHost(): string {
    let host = '';
    if (window['SERVER_INFO']) {
      host = window['SERVER_INFO'].appHost;
    }
    return host;
  }

  static getEpassHost(): string {
    let host = '';
    if (window['SERVER_INFO']) {
      host = window['SERVER_INFO'].epassHost;
    }
    return host;
  }
  static getLookerHost(): string {
    let host = '';
    if (window['SERVER_INFO']) {
      host = window['SERVER_INFO'].lookerHost;
    }
    return host;
  }

  static getOpinionLabUrl(window: Window, routeUrl: string, flowName: string, pageName: string): string {
    const opinionLabUrl = 'https://secure.opinionlab.com/ccc01/comment_card_d.asp?';
    const baseRefererUrl = 'https://completion.mb.verizonwireless.com/';
    const routeFragment = routeUrl.split('/ebilling/')[1];
    const customVars = {
      userId: window['mbtGlobal'].userId,
      ecpdId: window['mbtGlobal'].ecpdId,
      flowName,
      pageName,
      userEmail: window['mbtGlobal'].userEmail
    };

    let params = new HttpParams();
    params = params.set('referer', baseRefererUrl + Helper.transactionType[routeFragment]);
    params = params.set('customVars', JSON.stringify(customVars));

    return opinionLabUrl + params.toString();
  }

  // Apr 01, 2021 => 04-01-2021
  // to address mozilla replace '-' with '/' in date string
  static formatDate(value: string): string {
    const newValue = value ? value.replace(/\-/g, '/') : null;
    const date = new Date(newValue);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    return `${month}-${day}-${date.getFullYear()}`;
  }

  // 04-01-2021 => Apr 01, 2021 / Apr 01
  // to address mozilla replace '-' with '/' in date string
  static formatDate2(value: string | Date, year = true): string {
    const newValue = typeof value === 'string' ? value.replace(/\-/g, '/') : value;
    const config: any = { month: 'short', day: '2-digit' };
    if (year) {
      config.year = 'numeric';
    }
    return new Date(newValue).toLocaleString('en-us', config);
  }

  // Apr 01, 2021 => 04/01/2021
  static formatDate3(value: string): string {
    const newValue = value ? value.replace(/\-/g, '/') : null;
    const date = new Date(newValue);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    return `${month}/${day}/${date.getFullYear()}`;
  }

  //Fri Jul 07 2023 00:00:00 GMT+0530 (India Standard Time) to Fri Jul 07 2023 00:00:00 GMT+0530 (India Standard Time)  ==>  2023/07/20 to 2023/07/21
  static formatDate4(dateStr: string): string {
    const dateArr = dateStr?.split(' to ');
    const fromDate = new Date(dateArr[0]);
    const toDate = new Date(dateArr[1]);

    const fromYear = fromDate?.toLocaleString('default', { year: 'numeric' });
    const fromMonth = fromDate?.toLocaleString('default', { month: '2-digit' });
    const fromDay = fromDate?.toLocaleString('default', { day: '2-digit' });

    const toYear = toDate?.toLocaleString('default', { year: 'numeric' });
    const toMonth = toDate?.toLocaleString('default', { month: '2-digit' });
    const toDay = toDate?.toLocaleString('default', { day: '2-digit' });
    return `${fromYear}/${fromMonth}/${fromDay} to ${toYear}/${toMonth}/${toDay}`;
  }

  static getRemainingDays(endDate: string): number {
    const currentDate = new Date();
    const timeRemaining = Math.abs(new Date(endDate).getTime() - currentDate.getTime());
    return Math.ceil(timeRemaining / (1000 * 60 * 60 * 24));
  }

  static convertData(data: number, fromUnit: string, toUnit: string): number {
    const units = Object.keys(Helper.dataUnits);
    let fromIndex = units.indexOf(fromUnit);
    const toIndex = units.indexOf(toUnit);
    if (fromIndex !== toIndex) {
      while (fromIndex < units.length) {
        if (fromIndex < toIndex) {
          data = data / 1024;
          fromIndex++;
        } else if (fromIndex > toIndex) {
          data = data * 1024;
          fromIndex--;
        } else {
          break;
        }
      }
    }
    return Math.round(data * 1000) / 1000;
  }

  static convertToCsv(records: Map<string, string>[], header?: string[]) {
    const rows = [];
    const headerRow = header || [...records[0].keys()];
    rows.push(headerRow.join(','));
    for (const record of records) {
      const values = [...record.values()];
      const row = values.map((value) => {
        const escaped = ('' + value).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      rows.push(row.join(','));
    }
    return rows.join('\n');
  }

  static download(renderer: Renderer2, elementRef: ElementRef, response: HttpResponse<Blob>, aplicationType: string) {
    const fileName = response.headers.get('content-disposition').split('filename=')[1].replace(';', '');
    const nav = window.navigator as any;
    if (nav && nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(new Blob([response.body]), fileName);
    } else {
      const blobContent = new Blob([response.body], { type: aplicationType });
      const url = URL.createObjectURL(blobContent);
      const link = renderer.createElement('a');
      renderer.setAttribute(link, 'href', url);
      renderer.setAttribute(link, 'download', fileName);
      renderer.appendChild(elementRef.nativeElement, link);
      link.click();
      renderer.removeChild(elementRef.nativeElement, link);
    }
  }

  static print(window: Window, node: ElementRef) {
    const clonedNode = node.nativeElement.cloneNode(true);
    clonedNode.classList.add('print-content');
    window.document.body.appendChild(clonedNode);
    window.print();
    setTimeout(() => {
      window.document.body.removeChild(clonedNode);
    }, 0);
  }

  // static sortData(data: any, sort: MatSort): any {
  //   const active = sort.active;
  //   const direction = sort.direction;
  //   if (!active || !direction) {
  //     return data;
  //   }
  //   return data.sort(function (a: any, b: any) {
  //     let valueA = a[active];
  //     let valueB = b[active];
  //     if (valueA && valueB && valueA instanceof String && valueB instanceof String) {
  //       valueA = valueA.toLowerCase();
  //       valueB = valueB.toLowerCase();
  //     }
  //     let comparatorResult = 0;
  //     if (valueA != null && valueB != null) {
  //       if (valueA > valueB) {
  //         comparatorResult = 1;
  //       } else if (valueA < valueB) {
  //         comparatorResult = -1;
  //       }
  //     } else if (valueA != null) {
  //       comparatorResult = 1;
  //     } else if (valueB != null) {
  //       comparatorResult = -1;
  //     }
  //     return comparatorResult * (direction == 'asc' ? 1 : -1);
  //   });
  // }

  static getSessionItem(key: string): string {
    return sessionStorage.getItem(key);
  }

  static setSessionItem(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  static hasPilotFlag(flag: string): boolean {
    return window['pilotServiceList']?.indexOf(flag) > -1;
  }

  static hasPermissionList(flag: string): boolean {
    return window['umViewPermissionList']?.indexOf(flag) > -1;
  }

  static loadResources(resources: { type: string; url: string }[]): Promise<any[]> {
    const promises = [];
    resources.map((resource) => {
      promises.push(this.loadResource(resource.type, resource.url));
    });
    return Promise.all(promises);
  }

  static loadResource(type: string, url: string) {
    return new Promise((resolve, reject) => {
      let resource: HTMLScriptElement | HTMLLinkElement;
      if (type === 'js') {
        resource = document.createElement('script') as HTMLScriptElement;
        resource.type = 'text/javascript';
        resource.src = url;
        resource.async = false;
      } else if (type === 'css') {
        resource = document.createElement('link') as HTMLLinkElement;
        resource.rel = 'stylesheet';
        resource.href = url;
      }
      resource.onload = () => {
        resolve(url);
      };
      resource.onerror = () => {
        reject(url);
      };
      document.head.appendChild(resource);
    });
  }

  // static configureColumnChart(options?: {
  //   seriesData: PointOptionsObject[];
  //   xAxis: XAxisOptions[];
  //   dataLabels?: PlotColumnDataLabelsOptions;
  //   height?: number;
  //   plotOptions?: PlotOptions;
  //   tooltip?: TooltipOptions;
  //   yAxis?: YAxisOptions;
  // }): Options {
  //   const defaults = {
  //     yAxis: { visible: false },
  //     dataLabels: {},
  //     height: null,
  //     plotOptions: {},
  //     tooltip: { enabled: false }
  //   };
  //   const chartOptions = { ...defaults, ...options };

  //   return {
  //     title: { text: '' },
  //     chart: {
  //       style: { fontFamily: 'Verizon NHG TX' },
  //       height: chartOptions.height
  //     },
  //     series: [
  //       {
  //         name: 'Charges',
  //         type: 'column',
  //         data: chartOptions.seriesData,
  //         dataLabels: chartOptions.dataLabels
  //       }
  //     ],
  //     xAxis: chartOptions.xAxis as XAxisOptions[],
  //     yAxis: chartOptions.yAxis as YAxisOptions,
  //     plotOptions: chartOptions.plotOptions,
  //     tooltip: chartOptions.tooltip,
  //     legend: { enabled: false },
  //     credits: { enabled: false }
  //   };
  // }

  /**
   * Deep Cloning an object contains Data only.
   * Use this method when you don't have functions define in object
   * @param obj
   */
  static deepClone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  static subtractMonths(date: Date, month: any): Date {
    const newDateObj = new Date(Helper.deepClone(date));
    newDateObj.setMonth(newDateObj.getMonth() - month);
    return newDateObj;
  }
  static getGlobalMbtVariables(): any {
    return window['mbtGlobal'];
  }

  static getYearMonthDay(billCycleDate: string, fromDate: string, toDate: string): any {
    const dateListObj = {
      billCycleMonth: '',
      billCycleYear: '',
      billCycleAvailDate: '',
      billCycleFromDate: '',
      billCycleFromMonth: '',
      billCycleToDate: '',
      billCycleToMonth: ''
    };
    if (billCycleDate && fromDate && toDate) {
      const billCycleDateArr = billCycleDate.split('-');
      const fromDateArr = fromDate.split('-');
      const toDateArr = toDate.split('-');

      dateListObj.billCycleMonth = billCycleDateArr[1];
      dateListObj.billCycleYear = billCycleDateArr[2];
      dateListObj.billCycleAvailDate = `${billCycleDateArr[0]}${this.getDateOrdinal(billCycleDateArr[0])}`;
      dateListObj.billCycleFromDate = `${fromDateArr[0]}${this.getDateOrdinal(fromDateArr[0])}`;
      dateListObj.billCycleFromMonth = fromDateArr[1];
      dateListObj.billCycleToDate = `${toDateArr[0]}${this.getDateOrdinal(toDateArr[0])}`;
      dateListObj.billCycleToMonth = toDateArr[1];
      return dateListObj;
    }
  }

  static getDateOrdinal(day: string) {
    // To convert String into Number i.e. '22' => 22
    const date = day && parseInt(day);
    if (date > 3 && date < 21) return 'th';
    switch (date % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  static getAllAssociatedCompaniesEcpdId(): string[] {
    const obj = {
      associatedCompanies: [],
      ecpdIds: []
    };
    obj.associatedCompanies =
      window['mbtGlobal']?.associatedCompanies && JSON.parse(window['mbtGlobal']?.associatedCompanies);
    obj.ecpdIds = obj.associatedCompanies?.map((item) => item?.ecpdId);
    return obj.ecpdIds;
  }

  static isNumber(n): boolean {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

  //   static updateDataLayer(flowName: string, tagName: string) {
  //     if (window['vzwDL']) {
  //       setCustomVariables(window['vzwDL'].page, flowName);
  //       window['vzwDL'].authentication.userID = window['mbtGlobal'].userId;
  //       window['vzwDL'].authentication.ecpdId = window['mbtGlobal'].ecpdId;
  //     }

  //     const el = document.createElement('span');
  //     el.setAttribute('id', 'dtm_' + tagName.replace('-', '_'));
  //     el.setAttribute('class', 'datalayer_event');
  //     document.getElementsByTagName(tagName)[0].appendChild(el);
  //     document.getElementById('dtm_' + tagName.replace('-', '_')).click();

  //     updateOpinionLab(flowName);
  //     updateChatBot(flowName);
  //   }
}

// const updateOpinionLab = (flowName: string) => {
//   if (window['OOo'] && window['OOo'].oo_tab) {
//     setCustomVariables(window['OOo'].oo_tab.options.customVariables, flowName);
//   } else {
//     window.addEventListener(
//       'load',
//       function () {
//         if (window['OOo'] && window['OOo'].oo_tab) {
//           setCustomVariables(window['OOo'].oo_tab.options.customVariables, flowName);
//         }
//       },
//       false
//     );
//   }
// };

// const updateChatBot = (flowName: string) => {
//   const payload = { webChatPageName: flowName };
//   const message = JSON.stringify(payload);
//   const popupBot = <any>document.getElementById('popupBot');
//   if (popupBot && popupBot.contentWindow) {
//     popupBot.contentWindow.postMessage(message, '*');
//   }
// };

// const setCustomVariables = (global: any, flowName: string) => {
//   global.flowType = 'Billing & Reporting';
//   global.flowName = flowName;
//   global.pageName = '/mb/ebilling and reporting/' + flowName.toLowerCase();
// };
