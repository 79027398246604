<div class="wbts-app">
  <div>
    <app-header></app-header>
  </div>
  <main class="main-section page-wrapper">
    <app-loader></app-loader>
    <div class="borderClass">
      <router-outlet></router-outlet>
      <router-outlet name="ticketDetails"></router-outlet>
    </div>
  </main>
  <div>
    <app-footer>
      <div style="min-height: 100px" id="initialFooterBlock"></div>
    </app-footer>
  </div>
</div>
