import { ElementRef, Injectable, Renderer2 } from '@angular/core';
import { asset_path, getGlobalServerInfo } from '../../../../core/constants/aem.global';
declare let document: any;

export interface Scripts {
  name: string;
  src: string;
  type: string;
  loaded: false;
}

export class AemContent {
  aemKey: string;
  body: string;
  scripts: Scripts[];
  response: any;
  parsedHtml: Document;
}

@Injectable({
  providedIn: 'root'
})
export class DynamicScriptLoaderService {
  public scriptStore: Scripts[] = [];

  private unwantedAemScripts: string[] = [];

  private unwantedAemStyles: string[] = [];

  aemPodSlideRendere(parsedHtml: Document, renderer: Renderer2, aemTargetDivClass: string, elRef: ElementRef) {
    const refChild = elRef.nativeElement;
    aemTargetDivClass = `[class*='` + aemTargetDivClass + `']`;
    try {
      renderer.appendChild(refChild, parsedHtml.querySelectorAll(aemTargetDivClass)[0]);
    } catch (Error) {
      console.error(Error.message);
    }
  }

  aemPodCardRendere(parsedHtml: Document, renderer: Renderer2, targetElementClass: string, aemTargetDivClass: string) {
    aemTargetDivClass = `[class*='` + aemTargetDivClass + `']`;
    const element = renderer.selectRootElement(targetElementClass); // eg. (.className)
    try {
      renderer.appendChild(element, parsedHtml.querySelectorAll(aemTargetDivClass)[1]);
    } catch (Error) {
      console.error(Error.message);
    }
  }

  intializeScriptStore(scriptStore?: Scripts[]) {
    scriptStore ? (this.scriptStore = scriptStore) : (this.scriptStore = []);
  }

  getUpdatedScriptStore(): Scripts[] {
    return this.scriptStore;
  }

  aemScriptsFiltering(parsedHtml: Document, filetypes: string[]) {
    filetypes.forEach((filetype: string) => {
      const targetelement = filetype === 'js' ? 'script' : filetype === 'css' ? 'link' : 'none'; // determine element type to create nodelist from
      const targetattr = filetype === 'js' ? 'src' : filetype === 'css' ? 'href' : 'none'; // determine corresponding attribute to test for
      const allsuspects = parsedHtml.getElementsByTagName(targetelement);
      for (let i = allsuspects.length; i >= 0; i--) {
        // search backwards within nodelist for matching elements to remove
        if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null) {
          const url = allsuspects[i].getAttribute(targetattr);
          const filename = url.substring(url.lastIndexOf('/') + 1);
          this.addScripts(filename, allsuspects[i].getAttribute(targetattr), false, filetype);
          allsuspects[i]?.parentNode?.removeChild(allsuspects[i]); // remove scripts from internal AEM DIV and load at HEAD level;
        }
      }
    });
  }
  // To improve performance need to remove unecessary/duplicate scripts from AEM pod if anything requires just decalre them in global index.html
  aemScriptFilter(parsedHtml: Document) {
    this.aemScriptsFiltering(parsedHtml, ['js', 'css']);
  }

  checkDuplicateKey(name: string): any {
    return !!this.scriptStore.find((script) => script.name === name);
  }

  getLocalScriptsPath(name: string, path: string, local: boolean): any {
    let finalPath = '';
    if (this.checkDuplicateKey(name)) {
      return finalPath;
    }
    const staticHost = this.getStaticHost(local, getGlobalServerInfo()['staticHost']);
    if (path.indexOf('http') !== -1) {
      // Load External refernces dynamically
      finalPath = path;
    } else {
      finalPath = staticHost + path;
    }
    return finalPath;
  }

  addScripts(name: string, path: string, local: boolean, fileType: string) {
    const finalPath = this.getLocalScriptsPath(name, path, local);
    if (finalPath.length > 1) {
      this.scriptStore.push({ name: name, src: finalPath, type: fileType, loaded: false });
    }
  }

  getStaticHost(local: boolean, staticHost: string): string {
    console.log('getStaticHost::' + local + ' ::' + staticHost + asset_path);
    if (local) {
      return staticHost + asset_path; // when runing on env but using local assets
    } else {
      return staticHost; // when running on env but fetch from static server
    }
  }

  skipLoadingUnwantedFiles(name: string, type: string): boolean {
    if (type === 'css' && this.unwantedAemStyles.find((elm) => elm === name)) {
      return true;
    }
    if (type === 'js' && this.unwantedAemScripts.find((elm) => elm === name)) {
      return true;
    }
    return false;
  }

  load(url: string, tag: string, name: string): any {
    // This promise will be used by Promise.all to determine success or failure
    return new Promise(function (resolve, reject) {
      const element = document.createElement(tag);
      let parent = 'body';
      let attr = 'src';

      // Important success and error for the promise
      element.onload = function () {
        resolve({ name: name, src: url, type: element.type, loaded: true });
      };
      element.onerror = function () {
        reject({ name: name, src: url, type: element.type, loaded: false });
      };

      // Need to set different attributes depending on tag type
      switch (tag) {
        case 'script':
          element.async = true;
          break;
        case 'link':
          element.type = 'text/css';
          element.rel = 'stylesheet';
          attr = 'href';
          parent = 'head';
      }

      // Inject into document to kick off loading
      element[attr] = url;
      document[parent].appendChild(element);
      // document.getElementsByTagName('head')[0].appendChild(element);
    });
  }

  async loadAemScripts() {
    // Usage:  Load different file types with one callback
    const loaderList: any[] = [];
    this.scriptStore.forEach((item) => {
      if (item.type === 'css' && !this.skipLoadingUnwantedFiles(item.name, item.type)) {
        loaderList.push(this.load(item.src, 'link', item.name));
      }
      if (item.type === 'js' && !this.skipLoadingUnwantedFiles(item.name, item.type)) {
        loaderList.push(this.load(item.src, 'script', item.name));
      }
      if (item.type === 'img') {
        // any image go's here
      }
    });
    return Promise.all(loaderList);
  }

  addPageSpecificScript(code: string, _document: Document) {
    const s = _document.createElement('script');
    s.type = 'text/javascript';
    try {
      s.appendChild(_document.createTextNode(code));
      _document.body.appendChild(s);
    } catch (e) {
      s.text = code;
      _document.body.appendChild(s);
    }
  }

  addSimpleScript(url: string, id?: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    if (id) {
      script.id = id;
    }
    document.head.appendChild(script);
  }

  public extractAemContentBody(body: string): Document {
    const parser = new DOMParser();
    return parser.parseFromString(body, 'text/html');
  }
}
