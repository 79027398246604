import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InternalMessagingComponent } from './components/internal-messaging/internal-messaging.component';
import { TicketDetailsModalComponent } from './modals/ticket-details-modal/ticket-details-modal.component';
import { DialogModule } from 'primeng/dialog';
import { AccordionModule } from 'primeng/accordion';
import { WBTSService } from '../services/wbts.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LinksetGuard } from './guards/linkset.guard';
// import { UMRoleAuthGuard } from './guards/um-role-auth.guard';

@NgModule({
  declarations: [InternalMessagingComponent, TicketDetailsModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    AccordionModule,
    ButtonModule,
    InputTextModule,
    FileUploadModule,
    ToastModule,
    ConfirmDialogModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InternalMessagingComponent,
  ],
  providers: [WBTSService, MessageService, ConfirmationService, LinksetGuard],
})
export class SharedModule {}
