import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';


@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
  private requestCount: number;

  constructor(private loaderService: LoaderService) {
    this.requestCount = 0;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // bypass requests that don't require loader
    const bypassEndpoints = [
      '/commerce-footer.api',
      'classic/classic/cookie/set'
    ];
    if (bypassEndpoints.some((endpoint) => request.url.includes(endpoint))) {
      return next.handle(request);
    }

    this.requestCount++;
    this.loaderService.setLoader(true);
    return next.handle(request).pipe(
      finalize(() => {
        this.requestCount--;
        if (!this.requestCount) {
          this.loaderService.setLoader(false);
        }
      })
    );
  }
}
