import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { GridViewModule } from './components/grid-view/grid-view.module';
import { CreateTicketModule } from './components/create-ticket/create-ticket.module';
import { ProblemCategoryComponent } from './components/problemCategory/problem-category.component';
import { TicketDetailsModalComponent } from './shared/modals/ticket-details-modal/ticket-details-modal.component';

const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  {
    path: 'landing', loadChildren: () => import('./components/grid-view/grid-view.module').then(m => m.GridViewModule)
  },
  {
    path: 'problemCategory', component: ProblemCategoryComponent
  },
  {
    path: 'createTicket', loadChildren: () => import('./components/create-ticket/create-ticket.module').then(m => m.CreateTicketModule)
  },
  {
    path: 'editTicket', loadChildren: () => import('./components/create-ticket/create-ticket.module').then(m => m.CreateTicketModule)
  },
  {
    path: ':id', 
    component: TicketDetailsModalComponent, 
    outlet: 'ticketDetails' 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }]
})
export class AppRoutingModule { }
