/*
CookieService 
This service has methods to set and get local cookies
*/

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  xDomainCookie: any;
  static isDebug = !environment.production;
  VALID_ROOT_DOMAIN = ['vzwcorp.com', 'verizonwireless.com', 'verizon.com'];

  /* sets a cookie in current domain */
  setLocalCookie(cookieName: string, cookieValue: any, expiryDays = 5, secureOnly = true) {
    const date = new Date();
    date.setTime(date.getTime() + expiryDays * 1000 * 60 * 60 * 24);
    let cookieStr =
      cookieName +
      '=' +
      encodeURIComponent(cookieValue) +
      '; expires=' +
      date.toUTCString() +
      (secureOnly ? ';secure' : '');
    const rootDomain = this.getDomainTLD(window.location.hostname);
    if (this.VALID_ROOT_DOMAIN.find((domain) => rootDomain.lastIndexOf(domain) !== -1)) {
      cookieStr = cookieStr + ';domain=' + rootDomain + ';path=/';
      CookieService.isDebug && console.log('CookieService: Local cookie being set, ', cookieStr);
      document.cookie = cookieStr;
    }
  }

  /* gets value of a cookie from current domain */
  getLocalCookie(name) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
      const rootDomain: string = this.getDomainTLD(window.location.hostname);
      CookieService.isDebug &&
        console.log(`CookieService: Local cookie ${name} has value: ${decodeURIComponent(match[2])}`);
      return decodeURIComponent(match[2]);
    }
    return null; // Add a return statement to handle the case when no match is found
  }

  /* gets all cookies as a map */
  getLocalCookies() {
    const cstring = document.cookie.split(';'),
      cookies = {};
    cstring.forEach(function (cs) {
      const vals = cs.trim().split('=');
      if (!vals[0]) return;
      cookies[vals[0]] = decodeURIComponent(vals[1]);
    });
    return cookies;
  }

  getDomainTLD(domain) {
    if (undefined === domain || typeof domain != 'string') {
      return null;
    }

    try {
      const domainParts = domain.split('.');
      let domainTLD;
      if (domainParts.length > 1) {
        domainTLD = domainParts[domainParts.length - 2] + '.' + domainParts[domainParts.length - 1];
      } else domainTLD = domain;
      return domainTLD ? domainTLD : null;
    } catch (error) {
      return error;
    }
  }
}
