import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { AemService } from '../services/aem.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WINDOW } from '../../../../shared/utils/tokens';
import { MVBWebViewService } from '../services/mvb-webview.service';
import { CookieService } from 'src/app/shared/services/cookie.service';
declare const SERVER_INFO;

@Component({
  selector: 'app-footer',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MVBWebViewService, CookieService]
})
export class FooterComponent implements OnInit, OnDestroy {
  footerContent: string;
  stop$ = new Subject();

  constructor(
    @Inject(DOCUMENT) private _document: any,
    @Inject(WINDOW) private window,
    private aemService: AemService,
    private elRef: ElementRef,
    public renderer: Renderer2,
    private mvbWebViewService: MVBWebViewService
  ) {}

  ngOnInit() {
    if (!this.mvbWebViewService.isMVBRequest) {
      const el = document.querySelectorAll('app-footer')[0];
      const observer = new IntersectionObserver((event) => {
        if (event[0].intersectionRatio > 0) {
          this.initAemContent();
          this.initializeAemData();
          observer.unobserve(el);
          observer.disconnect();
        }
      });
      observer.observe(el);
    }
  }

  initAemContent() {
    this.aemService.aemStore$.pipe(takeUntil(this.stop$)).subscribe((item: any[]) => {
      if (!(item instanceof Array)) {
        //  console.log('BOBBY::not loading at first instance' + item);
      } else {
        item.forEach((aemContent: any) => {
          this.aemService.loadAemSlides(
            aemContent.parsedHtml,
            this.renderer,
            this._document,
            this.elRef,
            'blankpagepar'
          );
          //  }
        });

        if (document.querySelector('#initialFooterBlock')) {
          document.querySelector('#initialFooterBlock').remove();
        }
        setTimeout(() => {
          const img = document?.querySelector('img.vzw-footer-logo');
          this.renderer.setProperty(
            img,
            'src',
            `${SERVER_INFO?.appHost}/content/dam/mb_asset_folder/global-nav/css/logo-footer.png`
          );
        });
      }
    });
  }

  initializeAemData() {
    console.log('initializing Aem Keys');
    this.aemService
      .requestDataFromAEM()
      .pipe(takeUntil(this.stop$))
      .subscribe(
        (results) => {
          results.forEach((item, i) => {
            // this.saveResponseAPI(item,i);      // Don't uncomment it only for local purpose.
            this.aemService.populateAEMResponse(i, item);
          });
          this.aemService.pushAemContent();

          this.aemService.loadAemScripts();
        },
        (err) => {
          console.log('Aem items loaded error', err);
        }
      );
  }
  // Clear the subscription on destroy
  ngOnDestroy() {
    this.stop$.next(null);
  }
}
